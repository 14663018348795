import styled from "styled-components";
import { makeKeyDownHandler } from "../../utils/a11y-utils";

type Props = {
  selectedItem: string;
  items: string[];
  onSelect: (val: string) => void;
};

export const DotSelection = ({ selectedItem, items, onSelect }: Props) => {
  return (
    <DotContainer>
      {items.map((i) => {
        return (
          <div
            key={i}
            className={selectedItem === i ? "active" : ""}
            role="button"
            tabIndex={0}
            onKeyDown={makeKeyDownHandler(() => onSelect(i))}
            onClick={() => onSelect(i)}
          />
        );
      })}
    </DotContainer>
  );
};

const DotContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 40px;

  > * {
    width: 8px;
    height: 8px;
    margin: 16px 4px 0 0;
    background-color: var(--color-neutral-30);
    border-radius: 50%;
    cursor: pointer;

    &.active {
      background-color: var(--color-primary-50);
    }
  }
`;
