import { useGeneratePath } from "./useGeneratePath";

export function useParticipationItemUrl() {
  const generatePath = useGeneratePath();

  const participationItemUrl = (
    participationItemSlug?: string,
    includeMapState = false
  ) => {
    return generatePath({
      participationItemSlug: participationItemSlug || undefined,
      participationItemResultSlug: undefined,
      faqSlug: undefined,
      contentSlug: undefined
    }, true, includeMapState)
  };

  return participationItemUrl;
}
