import { MutableRefObject, useCallback, useEffect, useRef, useState } from "react";

interface UseTimeout<C extends (...args: any[]) => void> {
  (...args: Parameters<C>): void;
  immediate(...args: Parameters<C>): void;
  override(delay: number, ...args: Parameters<C>): void;
}

export const useTimeout = <C extends (...args: any[]) => void>(
  callback: C,
  mountRef: MutableRefObject<boolean>,
  delay: number
): UseTimeout<C> => {
  const [waiting, setWaiting] = useState(false);
  const memoizedCallback = useRef<C>(callback);

  useEffect(() => {
    memoizedCallback.current = callback;
  }, [callback]);

  const delayedCallback = useCallback(
    (...args: Parameters<C>) => {
      if (mountRef.current) {
        memoizedCallback.current(...args);
        setWaiting(false);
      }
    },
    [setWaiting, mountRef]
  );

  return Object.assign(
    useCallback(
      (...args: Parameters<C>) => {
        if (waiting) return;

        setWaiting(true);

        window.setTimeout(() => delayedCallback(...args), delay);
      },
      [delay, waiting, delayedCallback, setWaiting]
    ),
    {
      immediate: useCallback(
        (...args: Parameters<C>) => {
          if (!waiting) delayedCallback(...args);
        },
        [waiting, delayedCallback]
      ),

      override: useCallback(
        (overriddenDelay: number, ...args: Parameters<C>) => {
          if (waiting) return;

          setWaiting(true);

          window.setTimeout(() => delayedCallback(...args), overriddenDelay);
        },
        [waiting, delayedCallback, setWaiting]
      ),
    }
  );
};
