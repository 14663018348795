import { Marker } from "react-map-gl";
import { MarkerIconStyles } from "./MapStyles";
import { ReactComponent as UserPin } from "../../images/markers/pinUser.svg";

type Props = {
  latitude: number;
  longitude: number;
};

export const QuestionMarker = ({ latitude, longitude }: Props) => {
  return (
    <Marker latitude={latitude} longitude={longitude} offset={[2.5, -12.5]}>
      <MarkerIconStyles className="question">
        <UserPin />
      </MarkerIconStyles>
    </Marker>
  );
};
