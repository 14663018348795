type Props = {
  color: string;
};

export const PointLegendItem = ({ color }: Props) => {
  return (
    <svg viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 6a6 6 0 110 12 6 6 0 010-12zm0 2a4 4 0 100 8 4 4 0 000-8z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};
