import React from "react";
import { Link } from "react-router-dom";
import { useAppState } from "../../AppContext";
import { useRouteState } from "../../hooks/useRouteState";
import { MemoizedWordpressContentWrapper } from "../../shared/components/TextWrapper";
import { ReactComponent as LinkIcon } from "../../images/linkArrowRight.svg";
import IsDesktop from "../../shared/components/IsDeskop";
import { Faq, MapFaqData, Poi } from "../../types";
import IsMobile from "../../shared/components/IsMobile";
import { poiFromMapFaq } from "../../utils/faqUtils";
import { useContentUrl } from "../../hooks/useContentUrl";
import { FormattedMessage } from "react-intl";
import { messages } from "../../global-intl-messages";

export default function PoiContent() {
  const { state } = useAppState();
  const routeState = useRouteState();
  const contentUrl = useContentUrl();

  const poiSlug = routeState.poiSlug;
  let poi = state.pois.find((p) => p.slug === poiSlug);
  // @ts-ignore
  if (!poi) poi = state.areas.find((p) => p.slug === poiSlug);

  if (!poi) {
    let faq = state.faqs.find((f) => f.slug === routeState.poiSlug);
    if (!faq) return null;
    poi = poiFromMapFaq(faq as Faq<MapFaqData>);
  }

  if (!poi) return null;

  const PoiBody = ({ poi }: { poi: Poi }) => {
    return (
      <>
        <MemoizedWordpressContentWrapper content={poi.excerpt} />
        {poi.excerpt.trim() !== poi.content.trim() && poi.content && poi.type !== "faq" && (
          <p className="link">
            <Link to={contentUrl(poi.slug)}>
              <FormattedMessage {...messages.readMore} />{" "}
              <span className="icon">
                <LinkIcon />
              </span>
            </Link>
          </p>
        )}
      </>
    );
  };

  return (
    <>
      <IsDesktop>
        {poi.image && <img src={poi.image.sizes.medium_large} alt={poi.image.alt} />}
        <div>
          <h3 className="tiny" dangerouslySetInnerHTML={{ __html: poi.title }} />
          <PoiBody poi={poi} />
        </div>
      </IsDesktop>
      <IsMobile>
        <h3 className="tiny" dangerouslySetInnerHTML={{ __html: poi.title }} />
        {poi.image && <img src={poi.image.sizes.medium_large} alt={poi.image.alt} />}
        <PoiBody poi={poi} />
      </IsMobile>
    </>
  );
}
