import { AxiosRequestConfig } from "axios";
import { useEffect, useState } from "react";

export function useRestAuth() {
	const [restAuth, setRestAuth] = useState<AxiosRequestConfig>();
	const nonceInput = document.querySelector<HTMLInputElement>('body>[name=_wpnonce]');

	useEffect(() => {
		if (nonceInput) {
			setRestAuth({
				headers: {
					"X-WP-NONCE": nonceInput.value
				}
			});
		} else if (process.env.NODE_ENV === "development") {
			if (process.env.REACT_APP_USERNAME && process.env.REACT_APP_PASSWORD) {
				setRestAuth({
					auth: {
						username: process.env.REACT_APP_USERNAME,
						password: process.env.REACT_APP_PASSWORD
					}
				});
			}
		}
	}, [nonceInput, setRestAuth]);

	return restAuth;
}