import {
  WordpressLayerGroup,
  LayerGroup,
  Scenario,
  LayerGroupScenario,
  MapboxLayer,
} from "../types";
import mapboxgl, { Map } from "mapbox-gl";
import { decodeEntities } from "@wordpress/html-entities";
import { calculateViewport, generateBoundingBox } from "./mapUtils";

const transformWordpressLayerGroup = (
  layer: WordpressLayerGroup,
  scenarios: Scenario[]
): LayerGroup => {
  const layerScenarios = scenarios
    .map((scenario) => {
      const combinedLayers: string[] = [];
      if (layer.acf.mapbox_layer_groups) {
        const mapboxLayers = layer.acf.mapbox_layer_groups
          .map((wordpressLayer) => {
            if (wordpressLayer.scenarios && wordpressLayer.scenarios.includes(scenario.id)) {
              const mapboxLayer: MapboxLayer = {
                layers: Array.from(new Set(wordpressLayer.mapbox_layer_group.join(",").split(","))),
              };

              if (wordpressLayer.show_in_legend) {
                mapboxLayer.legendColor = wordpressLayer.legend_color;
                mapboxLayer.legendType = wordpressLayer.legend_type;
                mapboxLayer.legendTitle = wordpressLayer.legend_title;
              }

              combinedLayers.push(...mapboxLayer.layers);

              return mapboxLayer;
            }
            return null;
          })
          .filter(Boolean) as MapboxLayer[];

        if (mapboxLayers.length) {
          const layerGroupScenario: LayerGroupScenario = {
            id: scenario.id,
            slug: scenario.slug || `draft-${scenario.id}`,
            mapboxLayers,
            combinedLayers: Array.from(new Set(combinedLayers)),
            hasLegend: layer.acf.mapbox_layer_groups.some((l) => l.show_in_legend),
            pois: [],
          };
          return layerGroupScenario;
        }
      }
      return null;
    })
    .filter(Boolean) as LayerGroupScenario[];

  return {
    id: layer.id,
    title: decodeEntities(layer.title.rendered),
    content: layer.content.rendered,
    excerpt: layer.excerpt?.rendered,
    showInMenu: layer.acf.show_in_menu,
    slug: layer.slug || `draft-${layer.id}`,
    scenarios: layerScenarios,
    areas: [],
    bounds: layer.acf.bounding_box && generateBoundingBox(layer.acf.bounding_box),
    disableLegendPopup: Boolean(layer.acf.disable_legend_popup),
    status: layer.status,
  };
};

export const transformWordpressLayerGroups = (
  layers: WordpressLayerGroup[],
  scenarios: Scenario[]
): LayerGroup[] => {
  return layers.map((layer) => transformWordpressLayerGroup(layer, scenarios));
};

const flattenLayers = (layerGroups: LayerGroup[]) =>
  Array.from(new Set(layerGroups.flatMap((g) => g.scenarios.flatMap((s) => s.combinedLayers))));

export const activateLayerGroupFromScenarioAndSlug = (
  layerSlug: string | null,
  scenarioSlug: string,
  layerGroups: LayerGroup[],
  map: Map,
  flyTo?: mapboxgl.Map["flyTo"]
) => {
  const layersToHide = flattenLayers(layerGroups);

  if (layerSlug) {
    const layerGroup = layerGroups.find((l) => l.slug === layerSlug);

    if (!layerGroup?.scenarios.length) return;

    const groupToShow =
      layerGroup.scenarios.find((s) => s.slug === scenarioSlug) ?? layerGroup.scenarios[0];

    showLayers(groupToShow.combinedLayers, map);

    groupToShow.combinedLayers.forEach((l) => {
      while (layersToHide.includes(l)) {
        layersToHide.splice(layersToHide.indexOf(l), 1);
      }
    });

    if (layerGroup.bounds && flyTo) {
      const targetViewport = calculateViewport(layerGroup.bounds);

      flyTo(
        {
          center: [targetViewport.longitude, targetViewport.latitude],
          zoom: targetViewport.zoom,
        },
        { duration: 2000 }
      );
    }
  }

  hideLayers(layersToHide, map);
};

const hideLayers = (layers: string[], map: Map) => {
  layers.forEach((l) => {
    if (!map.getLayer(l)) {
      return;
    }
    map.setLayoutProperty(l, "visibility", "none");
  });
};

const showLayers = (layers: string[], map: Map) => {
  layers?.forEach((l) => {
    if (!map.getLayer(l)) {
      return;
    }
    map.setLayoutProperty(l, "visibility", "visible");
  });
};
