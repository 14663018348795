import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useAuthenticatedState } from "./hooks/useAuthenticatedState";
import { Capability, useCapability } from "./hooks/useCapability";
import { Feature, useFeature } from "./hooks/useFeature";
import { UseStateReturn } from "./types";

const nativeLog = console.log;
const nativeWarn = console.warn;
const nativeError = console.error;
const nativeInfo = console.info;

// es-lint-disable no-bitwise
export enum DebugLevel {
  None = 0,
  Errors = 1 << 0,
  Warnings = 1 << 1,
  Info = 1 << 2,
  Debug = 1 << 3,
}

export interface DebugMessage {
  type: DebugLevel;
  scope: string;
  message: string;
}

const NOOP = () => {};

const DebugContext = createContext<{
  messages: DebugMessage[];
  available: boolean;
  debug: (message: DebugMessage) => void;
  debugLevel: DebugLevel;
  toggleDebugType: (type: DebugLevel, state?: boolean) => void;
  clearMessage: (message?: DebugMessage) => void;
  maxLogs: number;
  setMaxLogs: UseStateReturn<number>[1];
}>({
  messages: [],
  available: false,
  debug: NOOP,
  debugLevel: DebugLevel.None,
  toggleDebugType: NOOP,
  clearMessage: NOOP,
  maxLogs: 100,
  setMaxLogs: NOOP,
});

export const DebugContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const debugFeatureAvailable = useFeature(Feature.Debug);
  const debugCapabilityAvailable = useCapability(Capability.DebugSep);

  const [debugLevel, internalSetDebugLevel] = useAuthenticatedState(
    DebugLevel.None,
    Capability.DebugSep
  );

  const [messages, setMessages] = useAuthenticatedState<DebugMessage[]>([], Capability.DebugSep);
  const locationQuery = window.location.search;

  const debugSessionActive = useMemo(() => {
    const search = new URLSearchParams(locationQuery);
    return search.has("debug");
  }, [locationQuery]);

  const debugLevelFromQuery = useMemo(() => {
    const search = new URLSearchParams(locationQuery);
    return Number(search.get("debug")) || 15;
  }, [locationQuery]);

  const [maxLogs, setMaxLogs] = useState(100);

  const messageCount = messages.length;

  useEffect(() => {
    if (messageCount > maxLogs) {
      setMessages((m) => m.slice(m.length - maxLogs));
    }
  }, [messageCount, setMessages, maxLogs]);

  const debug = useCallback(
    (message: DebugMessage) => {
      if (message.type > DebugLevel.None && (message.type & debugLevel) === message.type) {
        setMessages((messages) => [...messages, message]);
      }
    },
    [debugLevel, setMessages]
  );

  const uncaughtErrorHandler = useCallback(
    ({ message, error }: ErrorEvent) => {
      debug({
        type: DebugLevel.Errors,
        scope: error.type ?? "uncaught",
        message: message,
      });
    },
    [debug]
  );

  useEffect(() => {
    window.addEventListener("error", uncaughtErrorHandler);
    return () => window.removeEventListener("error", uncaughtErrorHandler);
  }, [uncaughtErrorHandler]);

  const patchedLog = useCallback(
    (...args: any[]) => {
      nativeLog(...args);
      debug({ type: DebugLevel.Debug, scope: "console", message: args.join(" ") });
    },
    [debug]
  );

  const patchedWarn = useCallback(
    (...args: any[]) => {
      nativeWarn(...args);
      debug({ type: DebugLevel.Warnings, scope: "console", message: args.join(" ") });
    },
    [debug]
  );

  const patchedError = useCallback(
    (...args: any[]) => {
      nativeError(...args);
      debug({ type: DebugLevel.Errors, scope: "console", message: args.join(" ") });
    },
    [debug]
  );

  const patchedInfo = useCallback(
    (...args: any[]) => {
      nativeInfo(...args);
      debug({ type: DebugLevel.Info, scope: "console", message: args.join(" ") });
    },
    [debug]
  );

  useEffect(() => {
    console.log = debugLevel & DebugLevel.Debug ? patchedLog : nativeLog;
    console.info = debugLevel & DebugLevel.Info ? patchedInfo : nativeInfo;
    console.warn = debugLevel & DebugLevel.Warnings ? patchedWarn : nativeWarn;
    console.error = debugLevel & DebugLevel.Errors ? patchedError : nativeError;
  }, [debugLevel, patchedLog, patchedWarn, patchedError, patchedInfo]);

  const setDebugLevel = useCallback(
    (level: DebugLevel) => {
      if (debugFeatureAvailable && debugSessionActive) internalSetDebugLevel(level);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debugFeatureAvailable, internalSetDebugLevel]
  );

  useEffect(() => setDebugLevel(debugLevelFromQuery), [debugLevelFromQuery, setDebugLevel]);

  const clearMessage = useCallback(
    (message?: DebugMessage) => {
      if (message) {
        setMessages((messages) => messages.filter((m) => m !== message));
      } else {
        setMessages([]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const toggleDebugType = useCallback(
    (type: DebugLevel, state?: boolean) => {
      if (state === undefined) {
        // toggle
        setDebugLevel(debugLevel ^ type);
      } else {
        // force state
        setDebugLevel(state ? debugLevel | type : debugLevel & ~type);
      }
    },
    [debugLevel, setDebugLevel]
  );

  const filteredMessages = useMemo(
    () => messages.filter((m) => (m.type & debugLevel) === m.type),
    [messages, debugLevel]
  );

  return (
    <DebugContext.Provider
      value={{
        messages: filteredMessages,
        available: debugFeatureAvailable && debugCapabilityAvailable && debugSessionActive,
        debugLevel,
        debug,
        clearMessage,
        toggleDebugType,
        maxLogs,
        setMaxLogs,
      }}
    >
      {children}
    </DebugContext.Provider>
  );
};

export const useDebugContext = () => useContext(DebugContext);
