import { WordpressScenario, Scenario } from "../types";
import { decodeEntities } from "@wordpress/html-entities";

const transformWordpressScenario = (scenario: WordpressScenario): Scenario => {
  return {
    id: scenario.id,
    title: decodeEntities(scenario.title.rendered),
    slug: scenario.slug || `draft-${scenario.id}`,
    status: scenario.status,
    showInMenu: scenario.acf.show_in_menu ?? true,
  };
};

export const transformWordpressScenarios = (scenarios: WordpressScenario[]): Scenario[] => {
  return scenarios.map(transformWordpressScenario);
};
