import { FormattedMessage } from "react-intl";
import { messages } from "../../global-intl-messages";
import Button from "../../shared/components/Button";
import { Modal, ModalProps } from "../../shared/components/Modal";

type Props = ModalProps & {
  message?: string;
};

export const SurveyWarningModal = (props: Props) => {
  return (
    <Modal {...props}>
      <FormattedMessage
        id="survey-warning-modal.title"
        defaultMessage="Location not allowed"
        tagName="h1"
      />
      <p>{props.message}</p>
      <Button onClick={() => props.onClose?.()}>
        <FormattedMessage {...messages.closeButton} />
      </Button>
    </Modal>
  );
};
