import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useReplaceStatePath } from "./useReplaceStatePath";
import { View } from "../components/Panorama/Krpano";

export function useSetPanoState() {
  const history = useHistory();
  const replaceStatePath = useReplaceStatePath();

  const setPanoState = useCallback(
    ({ hlookat, vlookat, fov }: View) => {
      const statePath = replaceStatePath({
        pathname: window.location.pathname,
        hlookat: +hlookat.toFixed(2),
        vlookat: +vlookat.toFixed(2),
        fov: +fov.toFixed(2),
      });
      history.replace(statePath);
    },
    [history, replaceStatePath]
  );

  return setPanoState;
}
