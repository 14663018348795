import { useMemo } from "react";
import { useLocation } from "react-router-dom";

const sidebarStateSplitRegex = /!([a-zA-Z\d]+)/;

const decompressMap = new Map()
  .set("n", "selectedNavigationItem")
  .set("s", "scenarioSlug")
  .set("e", "embed");

function decompressUrlState(compressedState: string) {
  return atob(compressedState)
    .split(",")
    .reduce<{ [key: string]: any }>((acc, item) => {
      const [key, value] = item.split("|") as [string, string];
      if (decompressMap.has(key)) acc[decompressMap.get(key)] = value;
      return acc;
    }, {});
}

export function useUrlState() {
  const { pathname } = useLocation();

  const urlState = useMemo(() => {
    const result = pathname.match(sidebarStateSplitRegex);
    return result ? decompressUrlState(result[1]) : {};
  }, [pathname]);

  return urlState;
}
