import styled from "styled-components";

const Button = styled.button`
  border: 0;
  border-radius: var(--border-radius-50);
  cursor: pointer;
  display: inline-block;
  font-family: var(--font-family-body);
  font-size: var(--font-size-default);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: var(--font-weight-30);
  line-height: var(--line-height-default);
  margin: 0;
  padding: var(--space-squish-25);
  text-decoration: none;
  background: var(--color-neutral-10);
  color: var(--color-neutral-70);

  &.active {
    background: var(--color-primary-30);
    color: var(--color-neutral-10);
  }

  &:visited {
    color: var(--color-neutral-70);
  }

  &:hover {
    background: var(--color-neutral-30);
    color: var(--color-neutral-70);
  }

  &:active,
  &:focus {
    box-shadow: inset 0 1px 3px color-mod(var(--color-black) alpha(80%));
  }

  &.small {
    font-size: var(--font-size-20);
    border-radius: var(--border-radius-default);
    padding: var(--space-squish-20);
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &.large {
    padding: var(--space-squish-30);
  }

  &.sv_next_btn,
  &.sv_complete_btn {
    margin-right: 0px !important;
  }

  &.sv_next_btn,
  &.sv_complete_btn,
  &.primary {
    background: var(--color-primary-50);
    color: var(--color-white);

    &:hover {
      background: var(--color-primary-70);
      color: var(--color-white);
    }

    &:visited {
      color: var(--color-white);
    }

    &:active,
    &:focus {
      box-shadow: inset 0 1px 3px color-mod(var(--color-black) alpha(80%));
    }
  }

  &.transparent {
    background-color: transparent;
    color: var(--color-primary-50);

    &:hover {
      background-color: transparent;
      color: var(--color-primary-50);
    }

    &:active,
    &:focus {
      box-shadow: 0 0 0;
    }
  }

  &.error,
  &.danger {
    background: var(--color-error-50);
    color: var(--color-white);

    &:visited {
      color: var(--color-white);
    }

    &:hover {
      background: var(--color-error-70);
      color: var(--color-white);
    }

    &:active,
    &:focus {
      box-shadow: inset 0 1px 3px color-mod(var(--color-black) alpha(80%));
    }
  }

  &.success {
    background: var(--color-success-50);
    color: var(--color-white);

    &:visited {
      color: var(--color-white);
    }

    &:hover {
      background: var(--color-success-70);
      color: var(--color-white);
    }

    &:active,
    &:focus {
      box-shadow: inset 0 1px 3px color-mod(var(--color-black) alpha(80%));
    }
  }

  &.disabled {
    background: var(--color-neutral-30);
    color: var(--color-neutral-50);
    pointer-events: none;

    &:visited {
      color: var(--color-neutral-50);
    }
  }
`;

export default Button;
