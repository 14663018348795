import { useMemo } from "react";
import { useLocation } from "react-router-dom";

const panoStateSplitRegex = /(-?\d*\.?\d+)h,(-?\d*\.?\d+)v,(-?\d*\.?\d+)*f,?/;

export function usePanoState() {
  const { pathname } = useLocation();

  const panoState = useMemo(() => {
    const result = pathname.match(panoStateSplitRegex);
    return result
      ? {
          hlookat: parseFloat(result[1]),
          vlookat: parseFloat(result[2]),
          fov: parseFloat(result[3]),
        }
      : null;
  }, [pathname]);

  return panoState;
}
