import { useAppState } from "../AppContext";
import { LayerGroup } from "../types";
import { useGeneratePath } from "./useGeneratePath";

export function useLayerGroupUrl() {
  const { state } = useAppState();
  const generatePath = useGeneratePath();

  const layerGroupUrl = (layerGroup: LayerGroup) => {
    let layerGroupSlug: string | undefined = layerGroup.slug;
    if (layerGroup.slug === state.map.defaultLayerGroupSlug) layerGroupSlug = undefined;

    return generatePath({
      layerGroupSlug,
      panoSlug: undefined,
      poiSlug: undefined,
      contentSlug: undefined,
      faqSlug: undefined,
      participationItemSlug: undefined,
      tourSlug: undefined,
      tourPointSlug: undefined,
      scenarioSlug: undefined,
      projectSlug: undefined,
      phaseSlug: undefined,
      map: "map",
    }, false, true);
  };

  return layerGroupUrl;
}
