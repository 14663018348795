import { useCallback } from "react";
import { useLocation } from "react-router-dom";

const orientationStateRegex = /\/@[^/?]*/;
const panoStateSplitRegex = /(-?\d*\.?\d+)h,(-?\d*\.?\d+)v,(-?\d*\.?\d+)*f,?/;
const mapStateSplitRegex = /(-?\d*\.?\d+),(-?\d*\.?\d+),(-?\d*\.?\d+)*z,?/;

export function useGetStatePath() {
  const { pathname } = useLocation();

  const getStatePath = useCallback(
    (includePanoState: boolean = false, includeMapState: boolean = true) => {
      let statePath = pathname.match(orientationStateRegex)?.[0] ?? "";

      if (!includePanoState) statePath = statePath.replace(panoStateSplitRegex, "");
      if (!includeMapState) statePath = statePath.replace(mapStateSplitRegex, "");

      return statePath === "/@" ? "" : statePath;
    },
    [pathname]
  );

  return getStatePath;
}
