import { NavigationItemIcon, NavigationItemText, StyledLink } from "./NavigationStyles";

import { ReactComponent as InfoIcon } from "./../../images/info.svg";
import { ReactComponent as MapsIcon } from "./../../images/map.svg";
import { ReactComponent as ProjectsIcon } from "./../../images/projects.svg";
import { ReactComponent as ChecklistIcon } from "./../../images/checklist.svg";
import { ReactComponent as FlagIcon } from "./../../images/flag.svg";
import { ReactComponent as ProfileIcon } from "./../../images/profile.svg";
import { ReactComponent as HomeIcon } from "./../../images/home.svg";
import { ReactComponent as AreasIcon } from "./../../images/areas.svg";
import React from "react";
import { ReactComponent as TourIcon } from "./../../images/tour.svg";
import { ReactComponent as CameraIcon } from "./../../images/camera.svg";
import classNames from "classnames";
import { useRouteState } from "../../hooks/useRouteState";
import { useUrlState } from "../../hooks/useUrlState";
import { ReservedMenuIndices } from "../../utils/menuUtils";
import { useReplaceStatePath } from "../../hooks/useReplaceStatePath";

type NavigationItemProps = {
  children: React.ReactNode;
  icon: string;
  index: number;
  to?: string;
  onClick?: () => void;
};

export default function NavigationItem({
  children,
  icon,
  to,
  index,
  onClick,
}: NavigationItemProps) {
  const routeState = useRouteState();
  const replaceStatePath = useReplaceStatePath();
  const urlState = useUrlState();

  const current = urlState.selectedNavigationItem
    ? Number(urlState.selectedNavigationItem)
    : ReservedMenuIndices.Home;

  if (to === undefined) to = routeState.pathname;
  if (to === "/") to = "";

  return (
    <StyledLink
      to={replaceStatePath({pathname: to, urlStateUpdate: {selectedNavigationItem: index}, keep: true})}
      className={classNames({
        active: current === index,
      })}
      onClick={onClick}
    >
      <NavigationItemIcon>{iconSwitch(icon)}</NavigationItemIcon>
      <NavigationItemText>{children}</NavigationItemText>
    </StyledLink>
  );
}

const iconSwitch = (iconName: string) => {
  switch (iconName) {
    case "home":
      return <HomeIcon />;
    case "map":
      return <MapsIcon />;
    case "areas":
      return <AreasIcon />;
    case "flag":
    case "phasing":
      return <FlagIcon />;
    case "profile":
      return <ProfileIcon />;
    case "clipboard":
      return <InfoIcon />;
    case "tour":
      return <TourIcon />;
    case "vr":
      return <CameraIcon />;
    case "folder":
      return <ProjectsIcon />;
    case "conversation":
      return <ChecklistIcon />;
    default:
      console.warn(`No icon found for icon name ${iconName} in the sidebar menu.`);
      return <InfoIcon />;
  }
};
