import React from "react";
import { useAppState } from "../../AppContext";
import classNames from "classnames";
import { useRouteState } from "../../hooks/useRouteState";
import { LayerGroupScenarioSwitchContainer, LayerGroupScenarioLink } from "./MapLayerGroupsStyles";
import { useUpdateStateUrl } from "../../hooks/useUpdateStateUrl";

export function LayerGroupScenarioSwitch() {
  const { state } = useAppState();
  const routeState = useRouteState();
  const updateStateUrl = useUpdateStateUrl();

  const layerGroup = state.map.layerGroups.find((l) => l.slug === routeState.activeLayerGroupSlug);

  if (!layerGroup?.scenarios || layerGroup.scenarios.length <= 1) {
    return null;
  }

  return (
    <LayerGroupScenarioSwitchContainer>
      {layerGroup.scenarios.map((obj) => {
        const scenario = state.scenarios.find((s) => s.slug === obj.slug);
        if (!scenario) return null;
        return (
          <LayerGroupScenarioLink
            key={scenario.slug}
            to={updateStateUrl({ scenarioSlug: scenario.slug })}
            className={classNames({
              active: scenario.slug === routeState.activeScenarioSlug,
            })}
          >
            {scenario.title}
          </LayerGroupScenarioLink>
        );
      })}
    </LayerGroupScenarioSwitchContainer>
  );
}
