import { useCallback, useEffect, useState } from "react";
import * as Survey from "survey-react";
import { ReactComponent as UserPin } from "../../images/markers/pinUser.svg";
import styled from "styled-components";
import { useRouteState } from "../../hooks/useRouteState";
import Button from "../../shared/components/Button";
import { useInterfaceState } from "../../InterfaceContext";
import { FormattedMessage } from "react-intl";
import { messages } from "../../global-intl-messages";

type Props = {
  question: Survey.Question;
};

export const LocationPicker = ({ question }: Props) => {
  const { setInterfaceState, interfaceState } = useInterfaceState();
  const routeState = useRouteState();
  const [picking, setPicking] = useState(false);
  const [location, setLocation] = useState<{
    latitude: number;
    longitude: number;
    layerSlug?: string;
    panoSlug?: string;
  } | null>(null);

  useEffect(() => {
    if (!location && question.value?.location) {
      setLocation(question.value.location);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clickhandler = useCallback(
    (event: Event) => {
      if (!isCustomEvent(event)) return;
      if (!picking) return;
      setInterfaceState({
        ...interfaceState,
        question: {
          pickingLocation: false,
          location: {
            latitude: event.detail.latitude,
            longitude: event.detail.longitude,
          },
        },
      });
      setPicking(false);
      setLocation({ ...event.detail });
      document.removeEventListener("mapclick", clickhandler);
      document.removeEventListener("panoclick", clickhandler);
    },
    [interfaceState, picking, setInterfaceState]
  );

  useEffect(() => {
    if (interfaceState.question?.pickingLocation !== picking) {
      setInterfaceState({
        ...interfaceState,
        question: { ...interfaceState.question, pickingLocation: picking },
      });
      if (picking) {
        document.addEventListener("mapclick", clickhandler);
        document.addEventListener("panoclick", clickhandler);
        question.value = "picking";
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [picking]);

  useEffect(() => {
    if (location) {
      const scenario = routeState.activeScenarioSlug;
      const layer = location?.layerSlug;
      const pano = location?.panoSlug;
      const isPano = location.panoSlug !== undefined;
      question.value = { location, type: isPano ? "pano" : "map", pano, layer, scenario };
    }
  }, [question, location, routeState.activeScenarioSlug]);

  return (
    <>
      <Button disabled style={{ display: "none" }} />
      <p className="u-d-f">
        <LocationButton onClick={() => setPicking(true)}>
          <UserPin />
          {location ? (
            <FormattedMessage
              id="participation-location-picker.current-location"
              defaultMessage="Currently selected location"
              description="Icon description for the pin depicting the currently picked location"
            />
          ) : (
            <FormattedMessage
              id="participation-location-picker.pick-location"
              defaultMessage="Please select a location"
              description="Button label to start picking a location, while no location has been selected yet"
            />
          )}
        </LocationButton>
        {picking && (
          <Button onClick={() => setPicking(false)} className="transparent">
            <FormattedMessage {...messages.cancelButton} />
          </Button>
        )}
        {!picking && location && (
          <Button onClick={() => setPicking(true)} className="transparent">
            <FormattedMessage
              id="participation-location-picker.pick-other-location"
              defaultMessage="Select other location"
              description="Button label to start picking new location, while a location has been selected."
            />
          </Button>
        )}
      </p>
    </>
  );
};

const LocationButton = styled.button`
  display: flex;
  line-height: 44px;
  border: 0;
  border-radius: var(--border-radius-50);
  cursor: pointer;
  font-family: var(--font-family-body);
  font-size: var(--font-size-default);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: var(--font-weight-30);
  margin: 0;
  padding: var(--space-squish-25);
  text-decoration: none;
  background: var(--color-neutral-10);
  color: var(--color-neutral-70);

  > svg {
    height: 44px;
    margin-right: var(--space-default);
  }
`;

export const locationPicker: Survey.QuestionCustomWidget = {
  name: "locationpicker",
  isDefaultRender: false,
  // @ts-ignore
  render: (question: Survey.Question) => <LocationPicker question={question} />,
  isFit: function (question: Survey.Question) {
    return question.getType() === "locationpicker";
  },
};

function isCustomEvent(evt: Event): evt is CustomEvent {
  return (evt as CustomEvent).detail !== undefined;
}
