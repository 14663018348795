import { useEffect, useReducer } from "react";
type PendingState<T> = [T | undefined, undefined, "pending"];
type ResolvedState<T> = [T, undefined, "resolved"];
type RejectedState = [undefined, Error, "rejected"];

function resolvePromise<T>(promise: Promise<T> | (() => Promise<T>) | undefined) {
  if (typeof promise === "function") {
    return promise();
  }

  return promise;
}

enum States {
  pending = "pending",
  rejected = "rejected",
  resolved = "resolved",
}

const defaultState = {
  error: undefined,
  result: undefined,
  state: States.pending,
};

function reducer(state: any, action: any) {
  switch (action.type) {
    case States.pending:
      return {
        error: undefined,
        result: action.payload,
        state: States.pending,
      };

    case States.resolved:
      return {
        error: undefined,
        result: action.payload,
        state: States.resolved,
      };

    case States.rejected:
      return {
        error: action.payload,
        result: undefined,
        state: States.rejected,
      };

    default:
      return state;
  }
}

function usePromise<T>(
  promise: Promise<T> | (() => Promise<T>) | undefined,
  inputs?: any[]
): PendingState<T> | ResolvedState<T> | RejectedState {
  const [{ error, result, state }, dispatch] = useReducer(reducer, defaultState);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    promise = resolvePromise(promise);

    if (!promise) {
      return;
    }

    let canceled = false;

    dispatch({ type: States.pending, payload: result ? (result as T) : null });

    promise.then(
      (result) =>
        !canceled &&
        dispatch({
          payload: result,
          type: States.resolved,
        }),
      (error) =>
        !canceled &&
        dispatch({
          payload: error,
          type: States.rejected,
        })
    );

    return () => {
      canceled = true;
    };
  }, inputs);

  return [result, error, state];
}

export default usePromise;
