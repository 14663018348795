import { WordpressPage, Page } from "../types";
import { decodeEntities } from "@wordpress/html-entities";

function transformWordpressPage(data: WordpressPage): Page {
  const linkName = data.acf?.page_link?.post_name ?? undefined;

  return {
    id: data.id,
    title: decodeEntities(data.title.rendered),
    content: data.content.rendered,
    excerpt: data.excerpt.rendered,
    showInMenu: !!data.acf?.show_in_menu,
    slug: data.slug || `draft-${data.id}`,
    featuredMedia: data.featured_media,
    pageLink: linkName
      ? {
          name: linkName,
        }
      : undefined,
    status: data.status,
  };
}

export function transformWordpressPages(data: WordpressPage[]) {
  return data.map(transformWordpressPage);
}
