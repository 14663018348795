interface LabelProps{
  color: string,
  content: string,
}

export function Label({color, content}: LabelProps): JSX.Element {
    return (
      <svg
        role="img"
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        fill={color}
        width="50"
        height="34"
        viewBox="0 0 75 50"
      >
        <title>Energie label met waarde: {content}</title>
        <polygon points="0,0 0,50 50,50 75,25 50,0" />
        <text fill="#ffffff" fontSize="30" fontFamily="Verdana" x="15" y="35">
          {content}
        </text>
      </svg>
    );
  }