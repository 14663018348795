import { LayerGroup } from "../types";

type Layer = mapboxgl.Layer & { name: string; group?: string };

export const visibleLegendItemLayers = (
  features: any,
  projectLayers: string[],
  layerGroups: LayerGroup[],
  wordpressConfig: any
) => {
  if (!features) return [];

  let layers: Layer[] = Array.from(
    new Set(
      features
        .map((feature: any) => ({
          ...feature.layer,
          name: feature.properties?.Name || feature.properties[wordpressConfig.key] || "empty",
          properties: feature.properties,
        }))
        .filter((layer: { id: string }) => projectLayers.includes(layer.id))
    )
  );

  if (!layers.length) {
    return [];
  }

  // @TODO Is dit nog wel nodig?
  layers.forEach(
    (l) =>
      (l.group = layerGroups.find((g) =>
        g.scenarios.some((s) => s.combinedLayers.includes(l.id))
      )?.title)
  );
  layers = removeDuplicates(layers, "name");
  if (layers.length > 1 && wordpressConfig.sortBy !== "")
    naturalSort(layers, wordpressConfig.sortBy);
  return layers;
};

export const allowedPopups = (
  features: any[],
  projectLayers: string[],
  layerGroups: LayerGroup[]
): Layer[] => {
  const layers = Array.from(
    new Set(
      features.map(
        (f: any): Layer => ({
          ...f.layer,
          name: f.properties?.Name || "",
        })
      )
    )
  );

  return layers.filter((layer) => {
    const layerGroup = layerGroups.find((g) =>
      g.scenarios.some((s) => s.combinedLayers.includes(layer.id))
    );
    return projectLayers.includes(layer.id) && !layerGroup?.disableLegendPopup;
  });
};

// @TODO Is dit nog wel nodig?
export const computeProjectLayers = (map: any) => {
  let layers: string[] = [];
  layers = layers.concat(map.baseLayers);
  map.layerGroups.forEach((group: LayerGroup) => {
    group.scenarios.forEach((scenarioLayers) => {
      layers = layers.concat(scenarioLayers.combinedLayers);
    });
  });

  return layers;
};

/**
 * sorts an array in alphabetical order, multi-digit numbers are treated as a single character
 * @param arr array that needs to be sorted
 * @param key key that will be used to compare two values
 */
function naturalSort(arr: any[], key: string): void {
  arr.sort((a, b) =>
    a.properties[key].toString().localeCompare(b.properties[key].toString(), undefined, {
      numeric: true,
      sensitivity: "base",
    })
  );
}
/**
 * removes duplicates(duplication happens by converting a dataset to a tileset)
 * @param arr array with duplicates that need to be removed
 * @param key key from element of array that will be uses to check if a value is duplicate
 */
function removeDuplicates(arr: any[], key: string): any[] {
  arr = arr.filter((v, i, a) => a.findIndex((t) => t[key] === v[key]) === i);
  return arr;
}
