type Props = {
  color: string;
};

export const DashedLegendItem = ({ color }: Props) => {
  return (
    <svg viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.121 7.05l2.122-2.121a2 2 0 112.828 2.828L16.95 9.88 14.12 7.05zm-7.07 7.071l2.828 2.829-2.122 2.121a2 2 0 11-2.828-2.828L7.05 14.12zm8.131-2.475l-3.536 3.536-2.828-2.828 3.536-3.536 2.828 2.828z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};
