import styled from "styled-components";
import React from "react";

interface BasicViewProps {
  layers: any[];
  getLayerColor(layer: any): string;
}

export function BasicView({ layers, getLayerColor }: BasicViewProps): JSX.Element {
  let items = layers.map((layer: any) => (
    <LegendItem key={layer.id}>
      <div style={{ backgroundColor: getLayerColor(layer) }}></div>
      <div>{layer.name || layer.id}</div>
    </LegendItem>
  ));
  return (
    <div>
      basicView
      {items}
    </div>
  );
}

const LegendItem = styled.div`
  display: flex;
  flex-direction: row;
  height: 20px;
  margin: var(--space-10) var(--space-10) 0;

  div:first-child {
    position: relative;
    width: 8px;
    height: 8px;
    margin-right: var(--space-20);
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
  }

  div + div {
    flex: 1;
  }
`;
