import { useReplaceStatePath } from "./useReplaceStatePath";

export function useUpdateStateUrl() {
  const replaceStatePath = useReplaceStatePath();

  const updateStateUrl = (
    urlStateUpdate: { [key: string]: any },
    to?: string,
    keep: boolean = false
  ) => {
    if (to !== undefined) {
      return replaceStatePath({ pathname: to, urlStateUpdate, keep });
    } else {
      return replaceStatePath({ pathname: window.location.pathname, urlStateUpdate, keep });
    }
  };

  return updateStateUrl;
}
