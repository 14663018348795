import React from "react";
import { StyledNavigationMobile } from "./NavigationMobileStyles";
import ActionList from "../../shared/components/ActionList";
import Action from "../../shared/components/Action";
import { ReactComponent as NavigationMenuIcon } from "./../../images/navigationMenu.svg";
import { useLocation } from "react-router-dom";
import { useInterfaceState } from "../../InterfaceContext";
import { messages } from "../../global-intl-messages";
import { FormattedMessage } from "react-intl";

export default function NavigationMobile() {
  const { interfaceState, setInterfaceState } = useInterfaceState();
  const { pathname } = useLocation();
  const participating = pathname.includes("participation");
  const tour = pathname.includes("tour");
  const question = pathname.includes("question");

  return (
    <StyledNavigationMobile>
      {!participating && !tour && !question && (
        <ActionList>
          <Action
            icon={<NavigationMenuIcon />}
            onClick={() => {
              setInterfaceState({
                ...interfaceState,
                sidebarOpen: !interfaceState.sidebarOpen,
              });
            }}
          >
            <FormattedMessage {...messages.menuButton} />
          </Action>
        </ActionList>
      )}
    </StyledNavigationMobile>
  );
}
