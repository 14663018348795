import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { useAppState } from "../../AppContext";
import { MemoizedWordpressContentWrapper } from "../../shared/components/TextWrapper";
import { ReactComponent as LinkArrowRight } from "../../images/linkArrowRight.svg";
import MobilePopup from "./MobilePopup";
import { useLocalStorage } from "../../hooks/useStorage";
import { useContentUrl } from "../../hooks/useContentUrl";
import { messages } from "../../global-intl-messages";
import { FormattedMessage } from "react-intl";

const sessionStorageKey = "closedMobileHomePage";

export const MobileHomePage = () => {
  const { state } = useAppState();
  const contentUrl = useContentUrl();
  const homePage = state.pages?.find((p) => p.slug === "home");
  const [viewedBefore, setViewedBefore] = useLocalStorage(sessionStorageKey);
  const onClose = useCallback(() => {
    setViewedBefore("true");
  }, [setViewedBefore]);

  if (viewedBefore === null && homePage) {
    return (
      <MobilePopup onClose={onClose}>
        <h3>{state.name}</h3>
        <MemoizedWordpressContentWrapper content={homePage.content} />
        {homePage.pageLink && (
          <p className="link">
            <Link to={contentUrl(homePage.pageLink.name)}>
              <FormattedMessage {...messages.readMore} />{" "}
              <span className="icon">
                <LinkArrowRight />
              </span>
            </Link>
          </p>
        )}
      </MobilePopup>
    );
  }

  return null;
};
