import { Phasing, WordpressPhasing } from "../types";
import { decodeEntities } from "@wordpress/html-entities";

function transformWordpressPhasing(data: WordpressPhasing): Phasing {
  const timeline = data.acf.timeline.map((i) => {
    return {
      title: i.title,
      date: new Date(i.date),
      description: i.description,
      pageLink: i.read_more,
    };
  });

  return {
    id: data.id,
    title: decodeEntities(data.title.rendered),
    content: data.content.rendered,
    excerpt: data.excerpt.rendered,
    slug: data.slug || `draft-${data.id}`,
    timeline,
  };
}

export function transformWordpressPhasings(data: WordpressPhasing[]) {
  return data.map(transformWordpressPhasing);
}
