import React, { useLayoutEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useAppState } from "../../AppContext";
import { usePoiUrl } from "../../hooks/usePoiUrl";
import { useRouteState } from "../../hooks/useRouteState";
import { Faq, MapFaqData } from "../../types";
import { poiFromMapFaq } from "../../utils/faqUtils";
import PoiContent from "./PoiContent";

type Props = React.PropsWithChildren<{
  onClose?: () => void;
}>;

export default function MobilePopup({ children, onClose }: Props) {
  const { state } = useAppState();
  const routeState = useRouteState();
  const history = useHistory();
  const poiUrl = usePoiUrl();
  const [marginTop, setMarginTop] = useState(window.innerHeight);
  const [scrolling, setScrolling] = useState(false);
  const [entered, setEntered] = useState(false);
  const [prevY, setPrevY] = useState(0);
  const [momentum, setMomentum] = useState(0);
  const scrollEl = useRef<HTMLDivElement>(null);
  const poiSlug = routeState.poiSlug;

  let poi = state.pois.find((p) => p.slug === poiSlug);
  // @ts-ignore
  if (!poi) poi = state.areas.find((p) => p.slug === poiSlug);

  useLayoutEffect(() => {
    setEntered(false);
    requestAnimationFrame(() => {
      setMarginTop(window.innerHeight * 0.67);
    });
    setTimeout(() => {
      setEntered(true);
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeState.poiSlug]);

  useLayoutEffect(() => {
    let timeout: any;
    if (momentum === 0 || scrolling) return;
    requestAnimationFrame(() => {
      if (momentum < 0) {
        setMomentum(momentum + 1);
      } else {
        setMomentum(momentum - 1);
      }
      if (marginTop >= window.innerHeight - 75 && entered) {
        setEntered(false);
        setMomentum(0);
        setMarginTop(window.innerHeight);
        timeout = setTimeout(() => {
          onClose?.();
          if (poiSlug) {
            history.replace(poiUrl());
          }
        }, 300);
        return;
      }
      changeMarginTopByDelta(momentum);
    });
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  });

  const changeMarginTopByDelta = (delta: number) => {
    const elementHeight = scrollEl.current?.getBoundingClientRect().height ?? 0;
    const newMT = Math.max(marginTop - delta, window.innerHeight - (elementHeight - 984));
    setMarginTop(newMT);
  };

  const drag = (e: React.TouchEvent<HTMLDivElement>) => {
    setScrolling(true);
    const currentY = e.touches[0].clientY;
    const delta = (prevY ? prevY : currentY + 1) - currentY;
    changeMarginTopByDelta(delta);
    setMomentum(delta);
    setPrevY(currentY);
  };

  const stopDrag = () => {
    setScrolling(false);
    if (marginTop > window.innerHeight - 75) {
      setEntered(false);
      setMomentum(0);
      setMarginTop(window.innerHeight);
      setTimeout(() => {
        onClose?.();
        if (poiSlug) {
          history.replace(poiUrl());
        }
      }, 300);
      return;
    }
  };

  const startMouseDrag = (e: React.MouseEvent<HTMLDivElement>) => {
    setScrolling(true);
    setPrevY(e.clientY);
  };

  const mouseDrag = (e: React.MouseEvent<HTMLDivElement>) => {
    if (scrolling) {
      const currentY = e.clientY;
      const delta = prevY - currentY;
      changeMarginTopByDelta(delta);
      setMomentum(delta);
      setPrevY(currentY);
    }
  };

  if (!poi) {
    let faq = state.faqs.find((f) => f.slug === routeState.poiSlug);
    if (!faq) return null;
    poi = poiFromMapFaq(faq as Faq<MapFaqData>);
  }

  if (poi || children) {
    return (
      <ScrollUp
        ref={scrollEl}
        onTouchMove={drag}
        onTouchEnd={stopDrag}
        onMouseMove={mouseDrag}
        onMouseUp={stopDrag}
        onMouseLeave={stopDrag}
        style={{ marginTop, transitionDuration: entered ? "0s" : "0.3s" }}
      >
        <div role="none" onMouseDown={startMouseDrag}></div> {poi ? <PoiContent /> : children}
      </ScrollUp>
    );
  }

  return null;
}

const ScrollUp = styled.div`
  position: absolute;
  overflow: auto;
  width: 100vw;
  min-height: 25vh;
  max-width: 100%;
  background: white;
  z-index: 15;
  border-radius: var(--border-radius-20) var(--border-radius-20) 0 0;
  padding: var(--space-default);
  box-shadow: var(--box-shadow-30);
  transition: margin-top 0s;
  padding-bottom: 1000px;

  > div:first-child {
    position: relative;
    width: 50px;
    height: 5px;
    border-radius: var(--border-radius-10);
    background: var(--color-neutral-30);
    margin: 0px auto var(--space-20);
    cursor: grab;

    &:after {
      display: block;
      content: "";
      position: absolute;
      width: calc(100% + 40px);
      height: calc(100% + 40px);
      transform: translate(-20px, -20px);
    }
  }

  img {
    width: 100%;
    max-height: 350px;
    object-fit: cover;
    border-radius: var(--space-20);
  }

  span {
    color: var(--color-primary-50);
    text-decoration: underline;
  }
`;
