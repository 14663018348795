export const ratioToPercentage = (time: number, totalTime: number) =>
  (time && totalTime ? time / totalTime : 0) * 100;

export const toTimeDisplay = (totalSeconds: number) => {
  const days = Math.floor(totalSeconds / (3600 * 24));
  const time = new Date(totalSeconds * 1000);

  const segmented = [
    time.getUTCHours() + 24 * days,
    time.getUTCMinutes().toString().padStart(2, "0"),
    time.getUTCSeconds().toString().padStart(2, "0"),
  ];

  return segmented.filter(Boolean).join(":");
};
