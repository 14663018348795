type Props = {
  color: string;
};

export const LineLegendItem = ({ color }: Props) => {
  return (
    <svg viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <rect
        fill={color}
        transform="rotate(-45 12 12)"
        x="2"
        y="10"
        width="20"
        height="4"
        rx="2"
        fillRule="evenodd"
      />
    </svg>
  );
};
