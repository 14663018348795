let channel: MessagePort;
let corsTransactionId = 1;
const queue: {
  resolver: (event: MessageEvent) => void;
  command: CORSCommand;
  message: CORSMessage;
}[] = [];

export enum CORSCommand {
  Init = "init",
  GetLocalStorage = "localStorage.getItem",
  SetLocalStorage = "localStorage.setItem",
  RemoveLocalStorage = "localStorage.removeItem",
  GetSessionStorage = "sessionStorage.getItem",
  SetSessionStorage = "sessionStorage.setItem",
  RemoveSessionStorage = "sessionStorage.removeItem",
}

export interface CORSMessage {
  transactionId: number,
  command?: CORSCommand,
  [key: string]: any
};

if (window !== window.top) {
  const init = (event: MessageEvent<CORSMessage>) => {
    if (event.data.command === CORSCommand.Init) {
      [channel] = event.ports;
      flushQueue();
      channel.postMessage({transactionId: event.data.transactionId});
      window.removeEventListener("message", init);
    }
  }
  window.addEventListener("message", init);
}

const flushQueue = () => {
  queue.splice(0).forEach(({resolver, command, message}) => {
    channel.addEventListener("message", resolver);
    channel.addEventListener("messageerror", resolver);
    channel.postMessage({command, ...message});
  });
  channel.start();
}

const post = (command: CORSCommand, data?: {[key: string]: any}) => {
  const transactionId = ++corsTransactionId;
  const message: CORSMessage = {
    transactionId,
    ...data
  };

  return new Promise<CORSMessage>((resolve, reject) => {
    const resolver = (event: MessageEvent<CORSMessage>) => {
      if (event.data.transactionId !== transactionId) return;

      channel.removeEventListener("message", resolver);
      channel.removeEventListener("messageerror", resolver);

      switch(event.type) {
        case "message": return resolve(event.data);
        case "messageerror": return reject(event.data);
      }
    }

    if (!channel) {
      queue.push({
        resolver,
        command,
        message
      });
    } else {
      channel.addEventListener("message", resolver);
      channel.addEventListener("messageerror", resolver);
      channel.postMessage({command, ...message});
    }
  });
}

export const getLocalStorage = (key: string) => {
  try {
    return Promise.resolve(localStorage.getItem(key));
  } catch (e) {
    return post(CORSCommand.GetLocalStorage, {key}).then(message => message.value as string | null);
  }
}

export const setLocalStorage = (key: string, value: string) => {
  try {
    return Promise.resolve(localStorage.setItem(key, value));
  } catch (e) {
    return post(CORSCommand.SetLocalStorage, {key, value});
  }
}

export const removeLocalStorage = (key: string) => {
  try {
    return Promise.resolve(localStorage.removeItem(key));
  } catch (e) {
    return post(CORSCommand.RemoveLocalStorage, {key});
  }
}

export const getSessionStorage = (key: string) => {
  try {
    return Promise.resolve(sessionStorage.getItem(key));
  } catch (e) {
    return post(CORSCommand.GetSessionStorage, {key}).then(message => message.value as string | null);
  }
}

export const setSessionStorage = (key: string, value: string) => {
  try {
    return Promise.resolve(sessionStorage.setItem(key, value));
  } catch (e) {
    return post(CORSCommand.SetSessionStorage, {key, value});
  }
}

export const removeSessionStorage = (key: string) => {
  try {
    return Promise.resolve(sessionStorage.removeItem(key));
  } catch (e) {
    return post(CORSCommand.RemoveSessionStorage, {key});
  }
}

export const mappedLocalStorage = new Map<string, string>();
export const mappedSessionStorage = new Map<string, string>();

try {
  Object.entries(localStorage).forEach(([key, value]) => mappedLocalStorage.set(key, value));
  Object.entries(sessionStorage).forEach(([key, value]) => mappedSessionStorage.set(key, value));
} catch (e) { /* ignore */ }
