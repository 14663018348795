import { useGeneratePath } from "./useGeneratePath";

export function useParticipationItemResultUrl() {
  const generatePath = useGeneratePath();

  const participationItemResultUrl = (participationItemSlug?: string, layerGroupSlug?: string, panoSlug?: string) => {
    return generatePath(
      {
        participationItemResultSlug: participationItemSlug,
        layerGroupSlug,
        panoSlug,
        faqSlug: undefined,
        contentSlug: undefined,
        poiSlug: undefined,
        participationItemSlug: undefined
      },
      !!panoSlug,
      true
    );
  };

  return participationItemResultUrl;
}
