import styled from "styled-components";

export const StyledNavigationMobile = styled.nav`
  grid-row: 2 / 3;
  grid-column: 1 / 2;
  position: relative;
  left: 16px;
  top: 16px;
  z-index: 60;
  width: fit-content;
  height: fit-content;
`;
