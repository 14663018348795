import React from "react";
import { StyledSidebarToggle } from "./SidebarStyles";
import Icon from "../../shared/components/Icon";
import { ReactComponent as SingleArrowLeftIcon } from "./../../images/singleArrowLeft.svg";
import { makeKeyDownHandler } from "../../utils/a11y-utils";

type SidebarToggleProps = {
  onClick: () => void;
};

export default function SidebarToggle({ onClick }: SidebarToggleProps) {
  return (
    <StyledSidebarToggle
      onClick={onClick}
      tabIndex={0}
      onKeyDown={makeKeyDownHandler(onClick)}
      role="button"
    >
      <Icon>
        <SingleArrowLeftIcon />
      </Icon>
    </StyledSidebarToggle>
  );
}
