import React, { useRef } from "react";
import { Route, RouteProps } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { useAppState } from "../../AppContext";

type AnimatedRouteProps = RouteProps & {
  component: any;
  timeout?: number;
  classNames?: string;
};

export default function AnimatedRoute({
  component,
  timeout = 300,
  classNames = "animate",
  ...rest
}: AnimatedRouteProps) {
  const { state } = useAppState();
  const Component = component;
  const nodeRef = useRef(null);
  if (state.initializing) return null;
  return (
    <Route
      {...rest}
      children={({ match }) => (
        <CSSTransition
          nodeRef={nodeRef}
          in={!!match}
          timeout={timeout}
          classNames={classNames}
          unmountOnExit
        >
          <Component ref={nodeRef} />
        </CSSTransition>
      )}
    />
  );
}
