import { Http } from "@status/codes";
import Axios from "axios";
import classNames from "classnames";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import usePromise from "../../hooks/usePromise";
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
} from "recharts";
import * as Survey from "survey-react";
import { useAppState } from "../../AppContext";
import { useParticipationItemResultUrl } from "../../hooks/useParticipationItemResultUrl";
import { useRouteState } from "../../hooks/useRouteState";
import { useInterfaceState } from "../../InterfaceContext";
import {
  StyledContent,
  ContentCloseLink,
  ContentScroll,
  StyledLayoutContent,
  ContentBody,
} from "../Content/ContentStyles";
import { CloseIconSpan } from "../Panorama/PanoStyles";
import { ReactComponent as CloseIcon } from "./../../images/close.svg";

/** @todo Implement/design backend for this. */
const predefinedQuestionData: { key: string; fill: string; stack: string | number }[] = [
  { key: "geschikt-zonnevelden", fill: "#F2AA25", stack: "a" },
  { key: "ongeschikt-zonnevelden", fill: "#FFDC9A", stack: "a" },
  { key: "geschikt-wind", fill: "#2196F3", stack: "a" },
  { key: "ongeschikt-wind", fill: "#B5DCFB", stack: "a" },
];

/** @todo Support more than just checkbox-questions and (stacked) barcharts */
export const ParticipationResults = () => {
  const participationItemResultUrl = useParticipationItemResultUrl();
  const { state } = useAppState();
  const { interfaceState } = useInterfaceState();
  const { participationItemResultSlug } = useRouteState();
  const participationItem = state.participation.find((p) => p.slug === participationItemResultSlug);

  const questionKeys = useMemo(() => predefinedQuestionData.map((q) => q.key), []);

  const [survey] = usePromise(async () => {
    if (!participationItem?.surveyId) return;
    const response = await Axios.get<any>(
      `/wp-json/the_imagineers/v1/survey/${participationItem.surveyId}`
    );
    if (response.status === Http.Ok) return new Survey.SurveyModel(response.data);
  }, [participationItem?.surveyId]);

  const questions = useMemo(
    () => survey?.getAllQuestions().filter((q) => questionKeys.includes(q.name)) ?? [],
    [survey, questionKeys]
  );

  const xAxisValues = useMemo(() => {
    const values: string[] = [];

    (questions as Survey.QuestionCheckboxBase[]).forEach((q) => {
      values.push(...q.choices.map((c) => c.value));
    });

    return Array.from(new Set(values));
  }, [questions]);

  const [surveyResults] = usePromise(async () => {
    if (!participationItem?.surveyId) return;
    const response = await Axios.get<{ data: { [key: string]: any } }[]>(
      `/wp-json/the_imagineers/v1/survey/${participationItem?.surveyId}/results?type=json`
    );
    if (response.status === Http.Ok) return response.data;
  }, [participationItem?.surveyId]);

  const groupedSurveyResults = useMemo(() => {
    if (!xAxisValues.length) return;

    const groupedResults = new Map<string, { [key: string]: number }>(
      xAxisValues.map((name) => [name, {}])
    );

    if (surveyResults?.length) {
      surveyResults.forEach(({ data }) => {
        questionKeys.forEach((name) => {
          if (!data[name]) return;
          data[name].forEach((key: any) => {
            const entry = groupedResults.get(key)!;

            if (entry[name]) entry[name]++;
            else entry[name] = 1;
          });
        });
      });
    }

    return Array.from(groupedResults)
      .map(([name, values]) => ({ name, ...values }))
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [surveyResults, xAxisValues, questionKeys]);

  if (!participationItem || !groupedSurveyResults) {
    return null;
  }

  return (
    <StyledLayoutContent
      className={classNames({
        "no-sidebar": !interfaceState.sidebarOpen,
      })}
    >
      <StyledContent>
        <ContentCloseLink to={participationItemResultUrl()}>
          <CloseIconSpan>
            <CloseIcon />
          </CloseIconSpan>
        </ContentCloseLink>
        <ContentScroll>
          <ContentBody>
            <h1>{participationItem.title}</h1>
            {surveyResults && (
              <FormattedMessage
                id="participation.results.respondents"
                defaultMessage={`{count, plural,
                      one {# respondent}
                      other {# respondents}
                  }`}
                values={{ count: surveyResults.length }}
              />
            )}
            {!!groupedSurveyResults.length && (
              <ResponsiveContainer width="100%" height={400}>
                <BarChart
                  height={300}
                  data={groupedSurveyResults}
                  margin={{
                    top: 20,
                    right: 40,
                    left: 0,
                    bottom: 20,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  {predefinedQuestionData.map(({ key, fill, stack }) => (
                    <Bar key={key} dataKey={key} stackId={stack} fill={fill} />
                  ))}
                </BarChart>
              </ResponsiveContainer>
            )}
          </ContentBody>
        </ContentScroll>
      </StyledContent>
    </StyledLayoutContent>
  );
};
