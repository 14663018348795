import React from "react";
import { StyledNavigation } from "./NavigationStyles";

type NavigationProps = {
  children: React.ReactNode;
  embed?: boolean;
};

function Navigation({ children, embed }: NavigationProps) {
  return (
    <StyledNavigation role="main-navigation" className={embed ? "embed" : ""}>
      {children}
    </StyledNavigation>
  );
}

export const MemoizedNavigation = React.memo(Navigation);
