import { Link } from "react-router-dom";
import styled from "styled-components";

export const FaqLink = styled(Link)`
    text-decoration: none;
  
  svg {
    stroke-width: 2px;
    fill: none;
    width: 12px;
    height: 12px;
    stroke: var(--color-primary-50);
  }
`;
