import classNames from "classnames";
import styled from "styled-components";
import { makeKeyDownHandler } from "../../../utils/a11y-utils";
import { ReactComponent as Legend } from "../../../images/legend.svg";
import { useState, useMemo, useCallback } from "react";
import { MapLayerLegend } from "../../Sidebar/MapLayerLegend";
import { useAppState } from "../../../AppContext";
import { useRouteState } from "../../../hooks/useRouteState";
import { isMapTourPoint } from "../../../utils/tourUtils";
import { useInterfaceState } from "../../../InterfaceContext";
import { useIntl } from "react-intl";

let initialActiveState = true;

export const LegendControl = () => {
  const [active, setActive] = useState(initialActiveState);
  const { state } = useAppState();
  const { participationState } = useInterfaceState();
  const routeState = useRouteState();
  const intl = useIntl();

  const layerGroup = useMemo(() => {
    return state.map.layerGroups.find((l) => l.slug === routeState.activeLayerGroupSlug);
  }, [state.map.layerGroups, routeState.activeLayerGroupSlug]);

  const participationLayer = useMemo(() => {
    return state.map.layerGroups.find((l) => l.slug === participationState.layerSlug) ?? null;
  }, [participationState.layerSlug, state.map.layerGroups]);

  const tour = state.tours.find((tour) => tour.slug === routeState.tourSlug);
  const tourPoint = routeState.tourPointSlug
    ? tour?.points.find((point) => point.slug === routeState.tourPointSlug)
    : undefined;
  let tourLayer;
  if (tourPoint && isMapTourPoint(tourPoint)) {
    tourLayer = tourPoint.data.layerGroup;
  }

  const legendAvailable =
    (layerGroup && layerGroup.scenarios.some((s) => s.hasLegend)) ||
    (tourPoint &&
      isMapTourPoint(tourPoint) &&
      tourPoint.data.layerGroup.scenarios.some((s) => s.hasLegend)) ||
    (participationLayer && participationLayer.scenarios.some((s) => s.hasLegend));

  const toggleActive = useCallback(() => {
    if (active && initialActiveState) initialActiveState = false;
    setActive(!active);
  }, [active, setActive]);

  if (!legendAvailable) return null;

  const activeLayer = participationLayer ?? tourLayer ?? layerGroup;

  const legendLabel = intl.formatMessage({
    id: "map.show-legenda-aria-label",
    defaultMessage: "Show legenda",
    description: "ARIA label for the show legenda button.",
  });

  return (
    <>
      <ControlContainer
        className={classNames("mapboxgl-ctrl mapboxgl-ctrl-group", {
          active: active,
        })}
      >
        <button
          className="mapboxgl-ctrl-icon pitchButton"
          type="button"
          onClick={toggleActive}
          onKeyDown={makeKeyDownHandler(toggleActive)}
        >
          <Legend title={legendLabel} aria-label={legendLabel} />
        </button>
      </ControlContainer>
      {active && (
        <LegendWrapper>{activeLayer && <MapLayerLegend layerGroup={activeLayer} />}</LegendWrapper>
      )}
    </>
  );
};

const ControlContainer = styled.div`
  position: absolute;
  bottom: -58px;
  right: 12px;

  button {
    svg {
      width: 60%;
      margin-top: 2px;
    }
  }

  &.active {
    background-color: var(--color-primary-50);

    button {
      color: var(--color-neutral-10);
    }
  }
`;

export const LegendWrapper = styled.div`
  position: absolute;
  background: white;
  right: 12px;
  bottom: -16px;
  min-width: 220px;
  padding: var(--space-30) var(--space-30) 0;
  border-radius: var(--space-10);
  box-shadow: var(--box-shadow-20);
`;
