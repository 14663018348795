import styled from "styled-components";

type Props = React.PropsWithChildren<{
  alignRight?: boolean;
}>;

export const PanoActions = ({ alignRight, children }: Props) => {
  const style = alignRight ? { right: 16 } : { left: 16 };

  return <PanoActionsContainer style={style}>{children}</PanoActionsContainer>;
};

const PanoActionsContainer = styled.div`
  position: absolute;
  z-index: 41;
  top: 16px;
`;
