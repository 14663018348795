import { useEffect, useMemo, useState } from "react";
import { useRestAuth } from "./useRestAuth";

export function useCapability(requiredCapability: Capability) {
  const restAuth = useRestAuth();
  const capsInput = document.querySelector<HTMLInputElement>("body>[name=_wpcaps]");
  const [caps, setCaps] = useState<Capability[]>([]);

  useEffect(() => {
    if (restAuth && capsInput) {
      setCaps(capsInput.value.split(",") as Capability[]);
    }
  }, [restAuth, capsInput, setCaps]);

  const result = useMemo(() => {
    return caps.includes(requiredCapability);
  }, [requiredCapability, caps]);

  return result;
}

export enum Capability {
  EditSep = "edit_sep",
  ReadDraft = "read_draft",
  DebugSep = "view_runtime_errors",
}
