import React from "react";
import styled from "styled-components";

type IconProps = {
  children?: React.ReactNode;
  className?: string;
};

export const IconSpan = styled.span`
  height: 24px;
  width: 24px;
  display: inline-flex;

  & svg {
    fill: none;
    stroke: currentColor;
    stroke-width: 1.5px;
  }
`;

export default function Icon({ children, className }: IconProps) {
  return <IconSpan className={className}>{children}</IconSpan>;
}
