import React from "react";
import Skeleton from "react-loading-skeleton";
import { Loader } from "../Panorama/PanoStyles";
import Sidebar from "./Sidebar";

export const FallbackSidebar = () => (
  <>
    <Sidebar />
    <Loader>
      <p className="inner">
        {/** @ts-ignore */}
        <Skeleton count={3} />
      </p>
    </Loader>
  </>
);
