import classNames from "classnames";
import styled from "styled-components";
import { makeKeyDownHandler } from "../../../utils/a11y-utils";
import { ReactComponent as Ruler } from "../../../images/ruler.svg";
import { FormattedMessage, useIntl } from "react-intl";

type Props = {
  onClick: () => void;
  active: boolean;
  pointsPlaced: boolean;
};

export const MeasureControl = ({ onClick, active, pointsPlaced }: Props) => {
  const intl = useIntl();

  const rulerLabel = intl.formatMessage({
    id: "map.measure-tool-aria-label",
    defaultMessage: "Measure distance",
    description: "ARIA label for the measuring tool.",
  });

  return (
    <ControlContainer
      className={classNames("mapboxgl-ctrl mapboxgl-ctrl-group", {
        active: active,
      })}
    >
      <button
        className="mapboxgl-ctrl-icon pitchButton"
        type="button"
        onClick={onClick}
        onKeyDown={makeKeyDownHandler(onClick)}
      >
        <Ruler title={rulerLabel} aria-label={rulerLabel} />
      </button>
      {active && !pointsPlaced && (
        <MessageWrapper>
          <FormattedMessage
            id="map.measure-tool-message"
            defaultMessage="Click twice on the map to start measuring distance, after this the placed points can be dragged."
            description="Message shown when the measuring tool is active and no points have been placed."
          />
        </MessageWrapper>
      )}
    </ControlContainer>
  );
};

const ControlContainer = styled.div`
  position: absolute;
  top: -40px;

  button {
    svg {
      width: 75%;
    }
  }

  &.active {
    background-color: var(--color-primary-50);

    button {
      color: var(--color-neutral-10);
    }
  }
`;

const MessageWrapper = styled.div`
  position: absolute;
  background: var(--color-white);
  color: var(--color-neutral-70);
  right: 2.35rem;
  top: 0;
  min-width: 220px;
  padding: var(--space-30) var(--space-30);
  border-radius: var(--space-10);
  box-shadow: var(--box-shadow-20);
`;
