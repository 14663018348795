import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import { useAppState } from "../../AppContext";
import { abbreviations } from "../../global-intl-messages";
import {
  StyledTimelineContainer,
  TimelineExcerpt,
  TimelineItem,
  TimelineTitleWrapper,
} from "./TimelineStyles";

const months = [
  <FormattedMessage {...abbreviations.january} />,
  <FormattedMessage {...abbreviations.februari} />,
  <FormattedMessage {...abbreviations.march} />,
  <FormattedMessage {...abbreviations.april} />,
  <FormattedMessage {...abbreviations.may} />,
  <FormattedMessage {...abbreviations.june} />,
  <FormattedMessage {...abbreviations.juli} />,
  <FormattedMessage {...abbreviations.august} />,
  <FormattedMessage {...abbreviations.september} />,
  <FormattedMessage {...abbreviations.oktober} />,
  <FormattedMessage {...abbreviations.november} />,
  <FormattedMessage {...abbreviations.december} />,
];

const now = new Date();

export const Timeline = () => {
  const { state } = useAppState();
  const phasingItem = state.phasing[0];

  if (!phasingItem) return null;

  return (
    <StyledTimelineContainer>
      {phasingItem.timeline.map((item, i) => {
        return (
          <TimelineItem
            key={i}
            className={classNames({
              complete: isComplete(item),
              active: !isComplete(item) && isComplete(phasingItem.timeline[i - 1]),
            })}
          >
            <TimelineTitleWrapper>
              <b>{item.title}</b>
              <div>
                {months[item.date.getMonth()]} {item.date.getFullYear()}
              </div>
            </TimelineTitleWrapper>
            <TimelineExcerpt>{item.description}</TimelineExcerpt>
          </TimelineItem>
        );
      })}
    </StyledTimelineContainer>
  );
};

const isComplete = (item?: { date: Date }) => {
  return !item || item.date < now;
};
