import styled from "styled-components";
import React from "react";

interface ListViewProps {
  layers: any[];
  isClickable: boolean;
  // setpopuplayers(clickedItem: any[]): void;
  selectLayer: (layer: any) => void;
  getLayerColor(layer: any): string;
  keyValue: string;
}

export function ListView({
  layers,
  isClickable,
  selectLayer,
  getLayerColor,
  keyValue,
}: ListViewProps): JSX.Element {
  if (isClickable) {
    const clickedItem: any[] = [];
    let items = layers.map((layer) => (
      <LegendItem
        key={layer.properties[keyValue]}
        onClick={() => {
          clickedItem.push(layer);
          selectLayer(layer);
        }
      }
      >
        <div style={{ backgroundColor: getLayerColor(layer) }}></div>
        <div>{layer.name || layer.id}</div>
      </LegendItem>
    ));
    return <div>{items}</div>;
  }
  let items = layers.map((layer) => (
    <LegendItem key={layer.properties[keyValue]}>
      <div style={{ backgroundColor: getLayerColor(layer) }}></div>
      <div>{layer.name || layer.id}</div>
    </LegendItem>
  ));
  return <div>{items}</div>;
}

const LegendItem = styled.div`
  display: flex;
  flex-direction: row;
  height: 20px;
  margin: var(--space-10) var(--space-10) 0;

  div:first-child {
    position: relative;
    width: 8px;
    height: 8px;
    margin-right: var(--space-20);
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
  }

  div + div {
    flex: 1;
  }
`;
