import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useAppState } from "./AppContext";
import { useAuthenticatedState } from "./hooks/useAuthenticatedState";
import { Capability, useCapability } from "./hooks/useCapability";
import { useSessionStorage } from "./hooks/useStorage";
import { EditorData, EditorDataMap } from "./utils/editorUtils";

type SetContextValue<K extends keyof IEditContext> = React.Dispatch<
  React.SetStateAction<IEditContext[K]>
>;

interface IEditContext {
  editing: boolean;
  setEditing: SetContextValue<"editing">;
  target?: number;
  setTarget: SetContextValue<"target">;
  data: { [key: number]: EditorData };
  editData: <T extends keyof EditorDataMap>(newData: Partial<EditorData<T>>) => void;
}

const initialState: IEditContext = Object.freeze({
  editing: false,
  setEditing: () => {},
  setTarget: () => {},
  data: {},
  editData: () => {}
});
const EditContext = createContext<IEditContext>(initialState);

export const EditStateProvider = ({ children }: PropsWithChildren<{}>) => {
  const canEditSep = useCapability(Capability.EditSep);
  const [editing, setEditing] = useAuthenticatedState<IEditContext["editing"]>(false, Capability.EditSep);
  const [target, setTarget] = useAuthenticatedState<IEditContext["target"]>(undefined, Capability.EditSep);
  const [storedEditorData, setStoredEditorData] = useSessionStorage("editorData");
  const [data, setData] = useAuthenticatedState<IEditContext["data"]>({}, Capability.EditSep);
  const { setShowDraft } = useAppState();
  const locationQuery = window.location.search;

  useEffect(() => {
    if (storedEditorData && !Object.keys(data).length) setData(JSON.parse(storedEditorData));
  }, [storedEditorData, data, setData]);

  const previewMode = useMemo(() => {
    const search = new URLSearchParams(locationQuery);
    return search.has("preview");
  }, [locationQuery]);

  useEffect(() => {
    setShowDraft(editing || previewMode);
  }, [editing, previewMode, setShowDraft]);

  const editData = useCallback(
    <T extends keyof EditorDataMap>(newData: Partial<EditorData<T>>) => {
      if (target) {
        data[target] = {
          ...data[target],
          ...newData,
        };
        setData({ ...data });
      }
    },
    [data, target, setData]
  );

  const beforeUnload = useCallback(() => {
    if (editing) {
      setStoredEditorData(JSON.stringify(data));
    }
  }, [data, editing, setStoredEditorData]);

  useEffect(() => {
    window.addEventListener("beforeunload", beforeUnload);
    return () => window.removeEventListener("beforeunload", beforeUnload);
  }, [beforeUnload]);

  return (
    <EditContext.Provider
      value={
        canEditSep
          ? {
              editing,
              setEditing,
              target,
              setTarget,
              data,
              editData,
            }
          : initialState
      }
    >
      {children}
    </EditContext.Provider>
  );
};

export const useEditState = () => useContext(EditContext);
