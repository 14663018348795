import styled from "styled-components";

export const StyledTimelineContainer = styled.div`
  display: flex;
  flex-direction: column;

  &:last-child() {
    &:after {
      display: none;
      padding-bottom: 0;
    }
  }
`;

export const TimelineExcerpt = styled.p`
  margin: var(--space-20) 0 var(--space-30);
  font-weight: var(--font-weight-20);
  overflow: hidden;
  position: relative;
`;

export const TimelineTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  * > {
    flex: 1;
  }

  div {
    color: var(--color-neutral-30);
  }
`;

export const TimelineItem = styled.div`
  position: relative;
  padding-left: var(--space-40);
  color: var(--color-neutral-50);
  font-weight: var(--font-weight-30);

  &:before {
    display: block;
    content: "";
    position: absolute;
    top: 4px;
    left: 6px;
    width: var(--space-20);
    height: var(--space-20);
    border-radius: 50%;
    box-shadow: 0 0 0 2px var(--color-neutral-50);
  }

  &:after {
    display: block;
    content: "";
    position: absolute;
    top: 24px;
    left: var(--space-20);
    width: var(--space-10);
    height: calc(100% - 30px);
    border-radius: var(--space-10);
    background-color: var(--color-neutral-30);
  }

  &.complete {
    &:before {
      box-shadow: 0 0 0 2px var(--color-success-50), 0 0 0 3px var(--color-success-30);
      background-color: var(--color-success-50);
    }
  }

  &.active {
    ${TimelineExcerpt} {
      max-height: unset;
      opacity: 1;
    }

    &:before {
      box-shadow: 0 0 0 2px var(--color-primary-50), 0 0 0 5px var(--color-primary-20);
      background-color: white;
    }

    b {
      color: var(--color-primary-50);
    }
  }
`;
