import { useGeneratePath } from "./useGeneratePath";

export function useFaqUrl() {
  const generatePath = useGeneratePath();

  const faqUrl = (faqSlug: string) => {
    return generatePath({
      contentSlug: undefined,
      faqSlug
    }, true, true);
  };

  return faqUrl;
}
