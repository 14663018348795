import React, { ReactNode, useCallback } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { useUrlState } from "../../hooks/useUrlState";
import { useReplaceStatePath } from "../../hooks/useReplaceStatePath";
import { ReservedMenuIndices } from "../../utils/menuUtils";
import { useInterfaceState } from "../../InterfaceContext";
import { useAppState } from "../../AppContext";

type HeaderProps = {
  children: ReactNode;
  logoPath?: string;
  alt: string;
  description?: string;
  loading?: boolean;
  homeUrlPath?: string;
};

const Div = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;
`;

const Logo = styled.div`
  display: flex;
  width: 80px;
  height: 80px;
  flex: 0 0 80px;
  overflow: hidden;
  border-radius: var(--border-radius-20);
  margin-right: 20px;

  & img {
    object-fit: contain;
    position: relative;
    display: block;
    max-width: 100%;
    max-height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const Title = styled.div`
  font-family: var(--font-family-heading);
  font-size: var(--font-size-60);
  margin: var(--space-stack-10);
  line-height: 1.2;
  font-weight: 500;
  margin-top: -4px;

  & a {
    text-decoration: none;
    color: currentColor;

    &:hover {
      color: var(--color-primary-50);
    }
  }
`;

const Subtitle = styled.div`
  font-size: var(--font-size-20);
  color: var(--color-neutral-50);
`;

export default function Header({
  loading = false,
  homeUrlPath = "",
  children,
  logoPath,
  alt,
  description,
}: HeaderProps) {
  const replaceStatePath = useReplaceStatePath();
  const { setInterfaceState } = useInterfaceState();
  const { state } = useAppState();
  const homeUrl = replaceStatePath({
    pathname: homeUrlPath,
    urlStateUpdate: { selectedNavigationItem: ReservedMenuIndices.Home },
    lat: undefined,
    long: undefined,
    zoom: undefined,
    hlookat: undefined,
    vlookat: undefined,
    fov: undefined,
  });
  const urlState = useUrlState();

  const returnToLanding = useCallback(() => {
    if (!state.landingPage) return;
    setInterfaceState((state) => ({ ...state, currentEntrance: null! }));
  }, [setInterfaceState, state.landingPage]);

  return (
    <Div>
      <Logo onClick={returnToLanding}>
        {loading ? (
          // @ts-ignore
          <Skeleton width={50} height={50} />
        ) : urlState.embed ? (
          <img alt={alt} src={logoPath} />
        ) : (
          <Link to={homeUrl}>
            <img alt={alt} src={logoPath} />
          </Link>
        )}
      </Logo>
      <div>
        <Title>
          {loading ? (
            // @ts-ignore
            <Skeleton width={200} height={25} />
          ) : urlState.embed ? (
            children
          ) : (
            // @ts-ignore
            <Link to={homeUrl}>{children}</Link>
          )}
        </Title>
        {/* @ts-ignore */}
        {loading && <Skeleton width={160} height={16} />}
        {!loading && description && <Subtitle>{description}</Subtitle>}
      </div>
    </Div>
  );
}
