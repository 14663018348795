import React from "react";
import {
  StyledProject,
  ProjectScroll,
  ProjectBody,
  PhaseContainer,
  PhaseLink,
  DateDisplay,
} from "./ProjectStyles";
import { MemoizedWordpressContentWrapper } from "../../shared/components/TextWrapper";
import IsMobile from "../../shared/components/IsMobile";
import { ContentCloseLink } from "../Content/ContentStyles";
import { useAppState } from "../../AppContext";
import { useRouteState } from "../../hooks/useRouteState";
import { ProjectPhase } from "../../types";
import classNames from "classnames";
import { Link } from "react-router-dom";

export const Project = () => {
  const { state } = useAppState();
  const routeState = useRouteState();
  const pj = state.projects.find((p) => p.slug === routeState.projectSlug);

  if (!pj) {
    return null;
  }

  let currentPhase: ProjectPhase | undefined = undefined;

  if (routeState.phaseSlug) {
    currentPhase = pj.phases.find((p) => p.slug === routeState.phaseSlug);
  }

  if (!currentPhase) {
    const now = Date.now();
    currentPhase = pj.phases.find((p) => {
      return new Date(p.startDate).getTime() < now && new Date(p.endDate).getTime() > now;
    });
  }

  if (!currentPhase) {
    currentPhase = pj.phases[0];
  }

  return (
    <StyledProject>
      <ProjectScroll>
        <ProjectBody>
          <h1>{pj.title}</h1>
          <MemoizedWordpressContentWrapper content={pj.content} />
          <PhaseContainer>
            {pj.phases.map((phase, index) => {
              return (
                <PhaseLink
                  key={phase.slug}
                  to={`/project/${pj.slug}/phase/${phase.slug}`}
                  className={classNames({
                    active: currentPhase === phase,
                  })}
                >
                  {index + 1}
                </PhaseLink>
              );
            })}
          </PhaseContainer>
          {currentPhase && (
            <div className="phaseDetails">
              <h2>{currentPhase.title}</h2>
              <DateDisplay>
                {new Date(currentPhase.startDate).toLocaleDateString()} -{" "}
                {new Date(currentPhase.endDate).toLocaleDateString()}
              </DateDisplay>
              <MemoizedWordpressContentWrapper content={currentPhase.content} />
              <div className="actions">
                {currentPhase.actions?.map((action) => {
                  return (
                    <Link key={action.title} to={action.url}>
                      {action.title}
                    </Link>
                  );
                })}
              </div>
              <div className="attachments">
                {currentPhase.attachments?.map((att: any) => {
                  return (
                    <a href={att} key={att}>
                      {att}
                    </a>
                  );
                })}
              </div>
            </div>
          )}
          <IsMobile>
            <ContentCloseLink to="/" />
          </IsMobile>
        </ProjectBody>
      </ProjectScroll>
    </StyledProject>
  );
};
