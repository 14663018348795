import Viewer from "react-viewer";

type Props = {
  url: string;
  onClose: () => void;
};

export const ImageViewer = ({ url, onClose }: Props) => {
  return (
    <Viewer images={[{ src: url }]} visible onClose={onClose} noNavbar noToolbar zIndex={100} />
  );
};
