import { decodeEntities } from "@wordpress/html-entities";
import {
  WordpressTour,
  TourItem,
  LayerGroup,
  WordpressTourPoint,
  TourPointItem,
  MapTourPointData,
  PanoTourPointData,
  Pano,
  Scenario,
} from "../types";
import { upsert } from "./areaUtils";

function transformWordpressTour(data: WordpressTour, layerGroups: LayerGroup[]): TourItem {
  const image = data.acf.image;

  if (data.acf.map_location) {
    const latitude = Number(data.acf.map_location.latitude);
    const longitude = Number(data.acf.map_location.longitude);
    const scenarios = data.acf.map_location.scenarios || [];
    const layers = data.acf.map_location.layers || [];

    // Add pois to scenario's in the map
    layerGroups
      .filter((l) => layers.includes(l.id))
      .forEach((layerGroup) => {
        layerGroup.scenarios
          .filter((s) => scenarios.includes(s.id))
          .forEach((scenario) => {
            upsert(["slug", "type"], scenario.pois, {
              slug: data.slug || `draft-${data.id}`,
              latitude,
              longitude,
              title: data.title.rendered,
              type: "question",
            });
          });
      });
  }

  const rv: TourItem = {
    id: data.id,
    title: decodeEntities(data.title.rendered),
    content: data.content.rendered,
    excerpt: data.excerpt.rendered,
    showInMenu: data.acf.show_in_menu,
    points: [],
    image,
    slug: data.slug || `draft-${data.id}`,
    status: data.status,
  };

  return rv;
}

function transformWordpressTourPoints(
  data: WordpressTourPoint,
  layerGroups: LayerGroup[],
  panos: Pano[],
  tours: TourItem[],
  scenarios: Scenario[]
): TourPointItem<MapTourPointData | PanoTourPointData> | undefined {
  let locationData: MapTourPointData | PanoTourPointData | undefined;

  if (data.acf.location_type === "map") {
    const layerId = data.acf.map!.layer;
    const bb = data.acf.map?.bounding_box.split(",").map(Number);

    const layerGroup = layerGroups.find((l) => l.id === layerId);

    if (!layerGroup) return;

    if (bb) {
      const boundingBox: [[number, number], [number, number]] = [
        [bb[1], bb[0]],
        [bb[3], bb[2]],
      ];
      locationData = { layerGroup, boundingBox };
    } else {
      locationData = { layerGroup };
    }
  } else if (data.acf.location_type === "pano") {
    const panoData = data.acf.pano!;
    const pitch = Number(panoData.pitch);
    const yaw = Number(panoData.yaw);
    const fov = Number(panoData.fov) || 70;
    const pano = panos.find((p) => p.id === panoData.panorama);

    if (!pano) return;

    locationData = { pano, pitch, yaw, fov };
  }

  const scenario = scenarios.find((s) => s.id === data.acf.scenario);

  return (
    scenario &&
    locationData && {
      id: data.id,
      type: data.acf.location_type,
      title: decodeEntities(data.title.rendered),
      content: data.content.rendered,
      excerpt: data.excerpt.rendered,
      slug: data.slug || `draft-${data.id}`,
      scenarioSlug: scenario.slug,
      data: locationData,
      tour: tours.find((t) => t.id === data.parent)!,
      status: data.status,
    }
  );
}

export function transformWordpressTours(
  data: (WordpressTour | WordpressTourPoint)[],
  layerGroups: LayerGroup[],
  panos: Pano[],
  scenarios: Scenario[]
) {
  const tours = data
    .filter((i) => !i.parent)
    .map((item) => transformWordpressTour(item as WordpressTour, layerGroups));
  const tourPoints = data
    .filter((i) => i.parent)
    .map((item) =>
      transformWordpressTourPoints(item as WordpressTourPoint, layerGroups, panos, tours, scenarios)
    )
    .filter(Boolean) as TourPointItem<MapTourPointData | PanoTourPointData>[];

  tours.forEach((tour) => {
    tour.points = tourPoints.filter((point) => point.tour === tour);
  });

  return { tours, tourPoints };
}

export function isMapTourPoint(
  tourPoint: TourPointItem<MapTourPointData | PanoTourPointData>
): tourPoint is TourPointItem<MapTourPointData> {
  return tourPoint.type === "map";
}

export function isPanoTourPoint(
  tourPoint: TourPointItem<MapTourPointData | PanoTourPointData>
): tourPoint is TourPointItem<PanoTourPointData> {
  return tourPoint.type === "pano";
}
