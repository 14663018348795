import React from "react";
import { DashedLegendItem } from "../../images/legend/DashedLegendItem";
import { LineLegendItem } from "../../images/legend/LineLegendItem";
import { PointLegendItem } from "../../images/legend/PointLegendItem";
import { PolygonLegendItem } from "../../images/legend/PolygonLegendItem";
import { LayerGroup } from "../../types";
import {
  LegendList,
  LegendListItem,
  LegendListItemColor,
  LegendListItemTitle,
  LegendListSymbol,
} from "./MapLayerGroupsStyles";

type Props = {
  layerGroup: LayerGroup;
  className?: string;
};

export const MapLayerLegend = ({ layerGroup, className = "" }: Props) => {
  const legendItems = layerGroup.scenarios.reduce<{
    [selector: string]: { color: string; type?: "point" | "line" | "dashed" | "polygon" };
  }>((accumulator, scenario) => {
    if (scenario.hasLegend) {
      scenario.mapboxLayers.forEach((layer) => {
        if (layer.legendTitle && layer.legendColor)
          accumulator[layer.legendTitle] = {
            color: layer.legendColor,
            type: layer.legendType,
          };
      });
    }
    return accumulator;
  }, {});

  return (
    <>
      {Object.keys(legendItems).length !== 0 && (
        <LegendList className={className}>
          {Object.keys(legendItems).map((key) => (
            <LegendListItem key={key}>
              <LegendListSymbol>
                {getLegendItem(legendItems[key].color, legendItems[key].type)}
              </LegendListSymbol>
              <LegendListItemTitle>{key}</LegendListItemTitle>
            </LegendListItem>
          ))}
        </LegendList>
      )}
    </>
  );
};

const getLegendItem = (color: string, type?: "point" | "line" | "dashed" | "polygon") => {
  switch (type) {
    case "point":
      return <PointLegendItem color={color} />;
    case "line":
      return <LineLegendItem color={color} />;
    case "dashed":
      return <DashedLegendItem color={color} />;
    case "polygon":
      return <PolygonLegendItem color={color} />;
    default:
      return <LegendListItemColor style={{ backgroundColor: color }} />;
  }
};
