import React, { useMemo } from "react";
import { useAppState } from "../../AppContext";
import { useTourUrl } from "../../hooks/useTourUrl";
import { MemoizedWordpressContentWrapper } from "../../shared/components/TextWrapper";
import { MenuItem } from "../../types";
import {
  StyledMenuLink,
  MenuItemTitle,
  MenuItemIntroduction,
  MenuItemImage,
} from "./MenuItemStyles";

export const ToursList = ({ menuItem }: { menuItem: MenuItem }) => {
  const { state } = useAppState();
  const tourUrl = useTourUrl();

  const tourListItems = useMemo(() => {
    const shown = state.tours.filter(
      (tour) => menuItem.children?.some((c) => c.page === tour.id) || tour.showInMenu
    );
    return shown.sort((tourA, tourB) => {
      if (!menuItem.children?.length) return 0;

      const indexA = menuItem.children.findIndex((c) => c.page === tourA.id);
      const indexB = menuItem.children.findIndex((c) => c.page === tourB.id);

      return indexA - indexB;
    });
  }, [menuItem, state.tours]);

  return (
    <>
      {tourListItems.map((tour) => {
        return (
          <StyledMenuLink key={tour.slug} to={tourUrl(tour.slug)} className="flex">
            {tour.image && (
              <MenuItemImage>
                <img src={tour.image.sizes.thumbnail} alt={tour.image.alt} />
              </MenuItemImage>
            )}
            <div>
              <MenuItemTitle>{tour.title}</MenuItemTitle>
              <MenuItemIntroduction>
                {tour.excerpt && <MemoizedWordpressContentWrapper content={tour.excerpt} />}
              </MenuItemIntroduction>
            </div>
          </StyledMenuLink>
        );
      })}
    </>
  );
};
