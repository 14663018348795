import { useGeneratePath } from "./useGeneratePath";

export function usePoiUrl() {
  const generatePath = useGeneratePath();

  const poiUrl = (poiSlug?: string) => {
    return generatePath({
      poiSlug
    }, true, true);
  };

  return poiUrl;
}
