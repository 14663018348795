import { useCallback, useState } from "react";
import { Capability, useCapability } from "./useCapability";

export function useAuthenticatedState<S>(init: S, capability: Capability) {
  const [state, internalSetState] = useState<S>(init);
  const hasCapability = useCapability(capability);

  const setState = useCallback(
    (state: S) => {
      if (hasCapability) internalSetState(state);
    },
    [hasCapability]
  );

  return [state, setState] as [S, React.Dispatch<React.SetStateAction<S>>];
}
