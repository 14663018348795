import { useCallback } from "react";
import { useMapState } from "./useMapState";
import { usePanoState } from "./usePanoState";
import { useUrlState } from "./useUrlState";

type StatePath = {
  lat?: number;
  long?: number;
  zoom?: number;
  hlookat?: number;
  vlookat?: number;
  fov?: number;
  urlStateUpdate?: { [key: string]: any };
  to?: string;
  pathname: string;
  keep?: boolean; // Keep other orientation states
};

const stateRegex = /\/@[^/!?]*(![a-zA-Z\d]+)?/;
const compressMap = new Map()
  .set("selectedNavigationItem", "n")
  .set("scenarioSlug", "s")
  .set("embed", "e");

function compressUrlState(state: { [key: string]: string }): string {
  const arr = Object.entries(state).reduce<string[]>((acc, [key, value]) => {
    if (value !== undefined) acc.push(`${compressMap.get(key)}|${value}`);
    return acc;
  }, []);
  return btoa(arr.join(",")).replace(/=/g, "");
}

export function useReplaceStatePath() {
  const panoState = usePanoState();
  const mapState = useMapState();
  const urlState = useUrlState();

  const replaceStatePath = useCallback(
    ({
      pathname,
      lat,
      long,
      zoom,
      hlookat,
      vlookat,
      fov,
      urlStateUpdate,
      keep = true,
    }: StatePath) => {
      const path = [];
      if (lat && long && zoom) path.push(`${lat},${long},${zoom}z`);
      else if (mapState && keep) path.push(`${mapState.lat},${mapState.long},${mapState.zoom}z`);

      if (!hlookat && panoState && keep) {
        hlookat = panoState.hlookat;
        vlookat = panoState.vlookat;
        fov = panoState.fov;
      }
      if (hlookat) path.push(`${hlookat}h,${vlookat}v,${fov}f`);
      if (urlStateUpdate || Object.keys(urlState).length !== 0) {
        path.push(`!${compressUrlState({ ...urlState, ...urlStateUpdate })}`);
      }

      return `${pathname.replace(stateRegex, "").replace(window.location.search, "")}/@${path.join(",")}${window.location.search}`;
    },
    [panoState, mapState, urlState]
  );

  return replaceStatePath;
}
