import React, { useCallback, useMemo, useRef, useState } from "react";
import { useAppState } from "../../AppContext";
import { useRouteState } from "../../hooks/useRouteState";
import { ParticipationWrapper } from "../Participation/ParticipationStyles";
import {
  SidebarFocusBackLink,
  SidebarFocusButtonContainer,
  SidebarFocusContent,
  SidebarFocusHeader,
  SidebarFocusHeadingHandle,
  StyledLayoutSidebarFocus,
  StyledSidebarFocus,
} from "../Sidebar/SidebarFocusStyles";
import { ReactComponent as DoubleArrowLeftIcon } from "./../../images/doubleArrowLeft.svg";
import { Link } from "react-router-dom";
import { MemoizedWordpressContentWrapper } from "../../shared/components/TextWrapper";
import { isMapTourPoint, isPanoTourPoint } from "../../utils/tourUtils";
import Button from "../../shared/components/Button";
import { ClickOverlay } from "../../shared/components/ClickOverlay";
import { getFaqItems } from "../../utils/faqUtils";
import { ReactComponent as LinkArrowRight } from "../../images/linkArrowRight.svg";
import { FaqLink } from "./TourStyles";
import { useTourUrl } from "../../hooks/useTourUrl";
import { useFaqUrl } from "../../hooks/useFaqUrl";
import { Feature, useFeature } from "../../hooks/useFeature";
import IsMobile from "../../shared/components/IsMobile";
import { FormattedMessage } from "react-intl";
import { messages } from "../../global-intl-messages";

export const Tour = () => {
  const { state } = useAppState();
  const faqsAvailable = useFeature(Feature.Faqs);
  const [showContent, setShowContent] = useState(true);

  const routeState = useRouteState();
  const { tourSlug, tourPointSlug } = routeState;
  const tourUrl = useTourUrl();
  const faqUrl = useFaqUrl();
  const textBoxRef = useRef<HTMLDivElement>(null);

  const tour = useMemo(
    () => state.tours.find((tour) => tour.slug === tourSlug) ?? null,
    [state.tours, tourSlug]
  );

  const scrollToTop = useCallback(() => {
    if (textBoxRef.current?.scrollTop) textBoxRef.current.scrollTop = 0;
  }, []);

  const tourPoint = useMemo(
    () => tour?.points.find((point) => point.slug === tourPointSlug),
    [tourPointSlug, tour?.points]
  );

  const tourPointIndex = useMemo(() => {
    if (!tour || !tourPoint) {
      return -1;
    }
    return tour.points.map((p) => p.id).indexOf(tourPoint.id);
  }, [tourPoint, tour]);

  const nextLink = useMemo(() => {
    if (!tour) return tourUrl();

    const nextTourPoint = tour.points[tourPointIndex + 1];

    if (nextTourPoint) {
      if (isPanoTourPoint(nextTourPoint)) {
        return (
          tourUrl(tour.slug, nextTourPoint.slug) +
          `/@${nextTourPoint.data.yaw}h,${nextTourPoint.data.pitch}v,${nextTourPoint.data.fov}f`
        );
      }
      if (isMapTourPoint(nextTourPoint)) {
        return tourUrl(tour.slug, nextTourPoint.slug);
      }
    } else {
      return tourUrl(tour.slug);
    }
    console.error(`Invalid tour point: ${nextTourPoint.slug}`);

    return tourUrl();
  }, [tour, tourPointIndex, tourUrl]);

  const prevLink = useMemo(() => {
    if (!tour) return tourUrl();

    const prevTourPoint = tour.points[tourPointIndex - 1];

    if (prevTourPoint) {
      if (isPanoTourPoint(prevTourPoint)) {
        return (
          tourUrl(tour.slug, prevTourPoint.slug) +
          `/@${prevTourPoint.data.yaw}h,${prevTourPoint.data.pitch}v,${prevTourPoint.data.fov}f`
        );
      }
      if (isMapTourPoint(prevTourPoint)) {
        return tourUrl(tour.slug, prevTourPoint.slug);
      }
    } else {
      return tourUrl(tour.slug);
    }
    console.error(`Invalid tour point: ${prevTourPoint.slug}`);

    return tourUrl();
  }, [tour, tourPointIndex, tourUrl]);

  const progress = useMemo(() => {
    if (!tour) return 0;

    return ((tourPointIndex + 1) / tour.points.length) * 100;
  }, [tour, tourPointIndex]);

  const faqSlug = useMemo(() => {
    if (tourPoint) {
      if (isMapTourPoint(tourPoint)) {
        return tourPoint.data.layerGroup.slug;
      }
      if (isPanoTourPoint(tourPoint)) {
        return tourPoint.data.pano.slug;
      }
    }
    return null;
  }, [tourPoint]);

  return (
    tour && (
      <>
        <IsMobile>
          {showContent && (
            <ClickOverlay
              onClick={(e) => {
                setShowContent(false);
                e.stopPropagation();
              }}
              onTouchStart={(e) => {
                e.stopPropagation();
              }}
              onTouchEnd={(e) => {
                e.stopPropagation();
              }}
            />
          )}
        </IsMobile>
        <StyledLayoutSidebarFocus
          className={"is-open is-narrow" + (showContent ? "" : " is-hidden")}
          id="tour-el"
        >
          <StyledSidebarFocus>
            <SidebarFocusHeader
              title={tour.title}
              subtitle={
                tourPoint ? (
                  <FormattedMessage
                    id="tour.step"
                    defaultMessage="Step {step} of {stepCount}"
                    description="Textual representation of the progress within a tour."
                    values={{ step: tourPointIndex + 1, stepCount: tour.points.length }}
                  />
                ) : null
              }
              progress={tourPoint ? progress : undefined}
            >
              <SidebarFocusBackLink
                to={tourUrl()}
                label={
                  <FormattedMessage
                    id="tour.return-to-overview-button"
                    defaultMessage="Return to overview"
                    description="Label for the back to overview button while in a tour."
                  />
                }
              />
            </SidebarFocusHeader>

            <SidebarFocusContent ref={textBoxRef}>
              <ParticipationWrapper>
                {tourPoint && (
                  <>
                    <h2>{tourPoint.title}</h2>
                    <MemoizedWordpressContentWrapper content={tourPoint.content} />
                  </>
                )}
                {!tourPoint && tour && <MemoizedWordpressContentWrapper content={tour.content} />}
                {faqsAvailable && faqSlug && getFaqItems(faqSlug, state.faqs).length !== 0 && (
                  <div style={{ paddingBottom: "10px" }}>
                    <h2>Wat vragen anderen?</h2>
                    <FaqLink to={faqUrl(faqSlug)}>
                      <FormattedMessage
                        id="tour.to-questions-and-answers"
                        defaultMessage="View questions and answers."
                        description="Link text for viewing questions and answers for the current viewpoint."
                      />{" "}
                      <span className="icon">
                        <LinkArrowRight />
                      </span>
                    </FaqLink>
                  </div>
                )}
              </ParticipationWrapper>
            </SidebarFocusContent>
            <SidebarFocusButtonContainer>
              {tourPoint && (
                <Link to={prevLink} onClick={scrollToTop}>
                  <Button className="secondary">
                    <FormattedMessage {...messages.backButton} />
                  </Button>
                </Link>
              )}
              {tourPointIndex !== tour.points.length - 1 && (
                <Link to={nextLink} onClick={scrollToTop}>
                  <Button className="primary">
                    {tourPoint ? (
                      <FormattedMessage {...messages.continueButton} />
                    ) : (
                      <FormattedMessage {...messages.startButton} />
                    )}
                  </Button>
                </Link>
              )}
              {tourPointIndex === tour.points.length - 1 && (
                <Link to={tourUrl()}>
                  <Button className="primary">
                    <FormattedMessage {...messages.finishButton} />
                  </Button>
                </Link>
              )}
            </SidebarFocusButtonContainer>
          </StyledSidebarFocus>
          <IsMobile>
            <SidebarFocusHeadingHandle onClick={() => setShowContent(true)}>
              <DoubleArrowLeftIcon />
            </SidebarFocusHeadingHandle>
          </IsMobile>
        </StyledLayoutSidebarFocus>
      </>
    )
  );
};
