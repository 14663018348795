import { useMemo } from "react";
import { useLocation } from "react-router-dom";

const mapStateSplitRegex = /(-?\d*\.?\d+),(-?\d*\.?\d+),(-?\d*\.?\d+)*z,?/;

export function useMapState() {
  const { pathname } = useLocation();

  const mapState = useMemo(() => {
    const result = pathname.match(mapStateSplitRegex);
    return result
      ? {
          lat: parseFloat(result[1]),
          long: parseFloat(result[2]),
          zoom: parseFloat(result[3]),
        }
      : null;
  }, [pathname]);

  return mapState;
}
