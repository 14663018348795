type Props = {
  color: string;
};

export const PolygonLegendItem = ({ color }: Props) => {
  return (
    <svg viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <rect fill={color} width="24" height="24" rx="8" fillRule="evenodd" />
    </svg>
  );
};
