import React from "react";
import Skeleton from "react-loading-skeleton";
import { ParticipationWrapper } from "../Participation/ParticipationStyles";
import {
  StyledLayoutSidebarFocus,
  StyledSidebarFocus,
  SidebarFocusHeading,
  SidebarFocusHeadingTitle,
  SidebarFocusContent,
} from "./SidebarFocusStyles";

export const FallbackSidebarFocus = () => {
  return (
    <StyledLayoutSidebarFocus className={"is-open"}>
      <StyledSidebarFocus>
        <SidebarFocusHeading>
          <SidebarFocusHeadingTitle>
            {/** @ts-ignore */}
            <Skeleton count={2} style={{ lineHeight: 2, marginBottom: 10 }} />
          </SidebarFocusHeadingTitle>
        </SidebarFocusHeading>
        <SidebarFocusContent>
          <ParticipationWrapper>
            {/** @ts-ignore */}
            <Skeleton count={4} style={{ lineHeight: 1.2, marginBottom: 10 }} />
          </ParticipationWrapper>
        </SidebarFocusContent>
      </StyledSidebarFocus>
    </StyledLayoutSidebarFocus>
  );
};
