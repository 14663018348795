import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useReplaceStatePath } from "./useReplaceStatePath";

export function useSetMapState() {
  const history = useHistory();
  const replaceStatePath = useReplaceStatePath();

  const setMapState = useCallback(
    (latitude?: number, longitude?: number, zoom?: number) => {
      const statePath = replaceStatePath({
        pathname: window.location.pathname,
        lat: latitude ? +latitude.toFixed(4) : undefined,
        long: longitude ? +longitude.toFixed(4) : undefined,
        zoom: zoom ? +zoom.toFixed(4) : undefined,
      });
      history.replace(statePath, "mapMove");
    },
    [history, replaceStatePath]
  );

  return setMapState;
}
