import React, { Fragment, useMemo, useCallback } from "react";
import { useAppState } from "../../AppContext";
import {
  MenuItemImage,
  MenuItemIntroduction,
  MenuItemTitle,
  StyledMenuLink,
  StyledResultLink,
} from "./MenuItemStyles";
import { MemoizedWordpressContentWrapper } from "../../shared/components/TextWrapper";
import { useParticipationItemUrl } from "../../hooks/useParticipationItemUrl";
import { MenuItem } from "../../types";
import { ParticipationItem } from "../../types";
import { useReplaceStatePath } from "../../hooks/useReplaceStatePath";
import { useMapState } from "../../hooks/useMapState";
import { isSurveyCompleted } from "../../utils/participationUtils";
import { useParticipationItemResultUrl } from "../../hooks/useParticipationItemResultUrl";
import { Feature, useFeature } from "../../hooks/useFeature";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { ReactComponent as LinkArrowRight } from "../../images/linkArrowRight.svg";

export default function ParticipationList({ menuItem }: { menuItem: MenuItem }) {
  const { state } = useAppState();
  const participationItemUrl = useParticipationItemUrl();
  const participationItemResultUrl = useParticipationItemResultUrl();
  const replaceStatePath = useReplaceStatePath();
  const mapState = useMapState();
  const participationResultsAvailable = useFeature(Feature.ParticipationResults);

  const generateUrl = useCallback(
    (p: ParticipationItem) => {
      let url = participationItemUrl(p.slug);
      if (p.latitude && p.longitude) {
        url = replaceStatePath({
          pathname: url,
          lat: p.latitude,
          long: p.longitude,
          zoom: mapState?.zoom,
        });
      }
      return url;
    },
    [participationItemUrl, replaceStatePath, mapState?.zoom]
  );

  const generateResultUrl = useCallback(
    (p: ParticipationItem) => {
      return participationItemResultUrl(p.slug, p.resultsLayergroupSlug, p.resultsPanoSlug);
    },
    [participationItemResultUrl]
  );

  const participationListItems = useMemo(() => {
    const shown = state.participation.filter(
      (participation) =>
        menuItem.children?.some((c) => c.page === participation.id) || participation.showInMenu
    );
    return shown.sort((participationA, participationB) => {
      if (!menuItem.children?.length) return 0;

      const indexA = menuItem.children.findIndex((c) => c.page === participationA.id);
      const indexB = menuItem.children.findIndex((c) => c.page === participationB.id);

      return indexA - indexB;
    });
  }, [menuItem, state.participation]);

  return (
    <>
      {participationListItems.map((participationItem) => {
        const targetUrl = generateUrl(participationItem);

        return (
          <Fragment key={participationItem.slug}>
            <StyledMenuLink to={targetUrl} className="flex disable-hover-effects">
              {participationItem.image && (
                <MenuItemImage>
                  <img
                    src={participationItem.image.sizes.thumbnail}
                    alt={participationItem.image.alt}
                  />
                </MenuItemImage>
              )}
              <div>
                <MenuItemTitle>{participationItem.title}</MenuItemTitle>
                <MenuItemIntroduction>
                  <MemoizedWordpressContentWrapper content={participationItem.content} />
                </MenuItemIntroduction>
                <p className="link">
                  <Link to={targetUrl}>
                    <FormattedMessage
                      id="participation-list.go-to-survey"
                      defaultMessage="Participate"
                      description="Link label for navigating to a survey."
                    />{" "}
                    <span className="icon">
                      <LinkArrowRight />
                    </span>
                  </Link>
                </p>
              </div>
            </StyledMenuLink>
            {participationResultsAvailable && isSurveyCompleted(participationItem) && (
              <StyledResultLink to={generateResultUrl(participationItem)}>
                <FormattedMessage
                  id="participation-list.show-results-button"
                  defaultMessage="View results"
                  description="Button label for opening a panel with survey results."
                />
              </StyledResultLink>
            )}
          </Fragment>
        );
      })}
    </>
  );
}
