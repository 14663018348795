import styled from "styled-components";
import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as LinkIcon } from "../../../images/linkArrowRight.svg";
import { Label } from "../Label";
import { useRouteState } from "../../../hooks/useRouteState";
import { FormattedMessage } from "react-intl";
import { messages } from "../../../global-intl-messages";

interface InfoViewProps {
  layers: any[];
}

const labelComparisonDescriptors = {
  a: "ruimschoots",
  b: "ruim",
  c: "net wel",
  d: "net niet",
  e: "niet",
  f: "niet",
  g: "bij lange na niet",
};
/**
 * TODO: values are hardcoded atm, need to figure a way out to make InfoView usefull for other Seps
 */
export function InfoView({ layers }: InfoViewProps) {
  const routeState = useRouteState();

  let labelColor = getLabelColor(layers[0].properties["huidig label (ber.)"]);

  if (routeState.activeLayerGroupSlug === "energielabel") {
    return (
      <InfoViewContainer>
        <div>
          <h3>
            {layers[0].properties["straat"]} {layers[0].properties["huisnummer"]}
          </h3>
          <Label color={labelColor} content={layers[0].properties["huidig label (ber.)"]} />
        </div>
        <br />
        <p>
          Met energielabel {layers[0].properties["huidig label (ber.)"]} voldoet deze woning{" "}
          {
            /** @ts-ignore */
            labelComparisonDescriptors[layers[0].properties["huidig label (ber.)"].toLowerCase()]
          }{" "}
          aan de isolatie-eisen
        </p>
        <Link to={"test"}>
          <FormattedMessage tagName="span" {...messages.readMore} />
          <LinkIcon />
        </Link>
      </InfoViewContainer>
    );
  } else if (routeState.activeLayerGroupSlug === "co2-uitstoot") {
    return (
      <InfoViewContainer>
        <div>
          <h3>
            {layers[0].properties["straat"]} {layers[0].properties["huisnummer"]}
          </h3>
        </div>
        <br />
        <p>
          Met een uitstoot van {layers[0].properties["CO2 (scen)"]} m<sup>2</sup> voldoet deze
          woning niet aan de duurzaamheidseisen.
        </p>
      </InfoViewContainer>
    );
  } else if (routeState.activeLayerGroupSlug === "bouwjaar") {
    return (
      <InfoViewContainer>
        <div>
          <h3>
            {layers[0].properties["straat"]} {layers[0].properties["huisnummer"]}
          </h3>
        </div>
        <br />
        <p>
          Met energielabel {layers[0].properties["huidig label (ber.)"]} heeft deze woning uit{" "}
          {layers[0].properties["bouwjaar"]}{" "}
          {["A", "B", "C"].includes(layers[0].properties["huidig label (ber.)"])
            ? "beter"
            : "minder goed"}{" "}
          energielabel dan gemiddeld.
        </p>
      </InfoViewContainer>
    );
  }
  return null;
}

const InfoViewContainer = styled.div`
  color: var(--color-neutral-70);
  max-width: 300px;
  margin: var(--space-10);
  div {
    div:last-child {
      max-width: 320px;
      margin: var(--space-10);
    }
    h3 {
      display: inline;
    }
    svg {
      float: right;
    }
  }

  p:last-child {
    margin-bottom: 0;
  }
  a {
    text-decoration: none;
    cursor: pointer;
    font-weight: 600;

    span {
      color: var(--color-primary-50);

      + svg {
        position: relative;
        top: var(--space-10);
        left: var(--space-10);
      }
    }
  }
`;

function getLabelColor(label: string): string {
  switch (label) {
    case "A+++":
    case "A++":
    case "A+":
    case "A":
      return "#006634";
    case "B":
      return "#018d37";
    case "C":
      return "#92c01f";
    case "D":
      return "#ffeb00";
    case "E":
      return "#f7b231";
    case "F":
      return "#e95a0c";
    case "G":
      return "#bc1622";
    default:
      return "#717171";
  }
}
