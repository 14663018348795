import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import { SidebarFocusBackLink } from "../components/Sidebar/SidebarFocusStyles";
import { messages } from "../global-intl-messages";
import { useParticipationItemUrl } from "../hooks/useParticipationItemUrl";
import { useSurveyState } from "../SurveyContext";

export const SurveyHeader = () => {
  const { surveyState, setActiveSurvey } = useSurveyState();
  const { survey, activePageId } = surveyState;
  const intl = useIntl();
  const participationItemUrl = useParticipationItemUrl();

  const page = survey?.pages.find((p) => p.id === activePageId);
  const index = page ? survey?.pages.indexOf(page) ?? 0 : 0;

  const progressBarWidth = useMemo(() => {
    if (!survey) return "10%";
    return ((index + 1) / survey?.pages.length) * 100 + "%";
  }, [index, survey]);

  return (
    <StyledSurveyHeader>
      <SidebarFocusBackLink
        to={participationItemUrl()}
        label={intl.formatMessage(messages.backButton)}
        onClick={() => setActiveSurvey(0)}
      />
      <h2>{survey?.name}</h2>
      <ProgressBar>
        <div style={{ width: progressBarWidth }}></div>
      </ProgressBar>
      <FormattedMessage
        id="survey.header.page-count"
        defaultMessage={"Page {index} of {total}"}
        values={{
          index: index + 1,
          total: survey?.pages.length,
        }}
      />
    </StyledSurveyHeader>
  );
};

const StyledSurveyHeader = styled.div`
  width: 100%;
  padding: 1rem;
  background-color: var(--color-neutral-10);
  border-radius: 0.25rem 0.25rem 0 0;
`;

const ProgressBar = styled.div`
  width: 100%;
  background-color: white;
  height: 0.75rem;
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;

  > div {
    background-color: var(--color-primary-50);
    height: 100%;
    border-radius: 0.25rem;
    transition: width 0.15s;
  }
`;
