import { useGeneratePath } from "./useGeneratePath";

export function useQuestionUrl() {
  const generatePath = useGeneratePath();

  const questionUrl = (open: boolean) => {
    return generatePath({
      question: open ? "question" : undefined
    }, true, true);
  };

  return questionUrl;
}
