import { useInterfaceState } from "../InterfaceContext";
import { useGeneratePath } from "./useGeneratePath";
import { useRouteState } from "./useRouteState";

export function useMapUrl() {
  const generatePath = useGeneratePath();
  const { interfaceState } = useInterfaceState();
  const routeState = useRouteState();

  return function mapUrl() {
    return generatePath(
      {
        panoSlug: interfaceState.currentEntrance.type !== "map" ? (routeState.panoSlug || interfaceState.currentEntrance.pano.slug) : undefined,
        map : "map"
      },
      false,
      true
    );
  };
}
